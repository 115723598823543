import './Pulsar.css';
import React, { useState } from 'react';
import Curso from './CursoXml';





const Subir = (valor) => {
  var arriba ="^^ Return ^^"

  if (valor!==0) {
          return (
            <section class="contenido">
          <a class="logo" href="#"><div class="button" id="ult">{arriba}
  </div></a></section>
          )
        }
}


/**Muestra el index del curso XML */
function CursoXml() {
  const [seleccion, setSeleccion] = React.useState(0);
 

        return (
            
        <>

        <section class="contenido">

            <h1 class= "tituloInicial">Lenguaje de marcas <br /><br /> Extensible Markup Language XML</h1>


            <a class="xmlMenu"  onClick={() => setSeleccion(1)}>Introducción</a>

            <a class="xmlMenu"  onClick={() => setSeleccion(2)}>Etiquetas</a>

            <a class="xmlMenu"  onClick={() => setSeleccion(3)}>Caso de uso, organización con Latex</a>

            <a class="xmlMenu"  onClick={() => setSeleccion(4)}>Caso de uso, diseño con Android</a>
            
            <br /><br /><br /><br /><br /><br />

        </section>
              
              
              
        {Curso(seleccion)}
      
        {Subir(seleccion)}

              
            </>

                   );
                   
}

export default CursoXml;
