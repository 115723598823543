
import './Pulsar.css';
import React, { useState } from 'react';
import Curso from './CursoJava.js';





const Subir = (valor) => {
  var arriba ="^^ Return ^^"

  if (valor!==0) {
          return (
            <section class="contenido">
          <a class="logo" href="#"><div class="button" id="ult">{arriba}
  </div></a></section>
          )
        }
}



function App() {
  const [seleccion, setSeleccion] = React.useState(0);
  var ejemplo8="import java.io.Serializable;\n/**\n*\n* @author alberto\n*/\npublic class PulsarLaTecla implements Serializable{\n/*Atributos del objeto*/\nprivate String temas;\nprivate int notas;\n\n/*Getters y setters*/\npublic String getTemas() {\nreturn temas;\n}\n\npublic void setTemas(String temas)\n{\n\nthis.temas = temas;\n}\n\npublic int getNotas() {\nreturn notas;\n}\n\npublic void setNotas(int notas) {\nthis.notas = notas;\n}\n}"
 

        return (
            
        <>

              <section class="contenido">

                <h1 class= "tituloInicial">El lenguaje Java</h1>

                <a class="curso" href='#punto' onClick={() => setSeleccion(1)} >La programación</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(2)}>Introducción y preparación de nuestra máquina</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(3)}>¿Cómo programar?</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(4)}>Entendiendo Netbeans</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(5)}>Documentar el código</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(6)}>Variables e identificadores</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(7)}>Tipos de datos</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(8)}>Operadores y expresiones</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(9)}>Estructuras y bucles. If, while, for, switch...</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(10)}>Entrada y salida de datos</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(11)}>Programación orientada a objetos. Clases, métodos...</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(12)}>Estructuras de almacenamiento</a>

                <a class="curso" href='#punto' onClick={() => setSeleccion(13)}>Almacenamientos de datos. (Flujos y ficheros)</a>

                <a class="curso" id="ult" href='#punto' onClick={() => setSeleccion(14)}>Almacenamientos de datos. (Serialización y persistencia)</a>

                


              </section>
              
              
              
        {Curso(seleccion)}
      
        {Subir(seleccion)}

              
            </>

                   );
                   
}

export default App;
