import "highlight.js/styles/night-owl.css";
import Highlight from "react-highlight";
import './Pulsar.css';
import img01 from './todos_recursos/entrada.jpg'
import img0 from './todos_recursos/Sun.png'
import img from './todos_recursos/ordenador.png'
import img1 from './todos_recursos/ia.png'
import img2 from './todos_recursos/java.png'
import img3 from './todos_recursos/misterio.jpg'
import img4 from './todos_recursos/errorNetbeans.png'
import img5 from './todos_recursos/netbeans1.png'
import img6 from './todos_recursos/netbeans2.png'
import img7 from './todos_recursos/netbeans3.png'
import img8 from './todos_recursos/netbeans4.png'
import img9 from './todos_recursos/netbeans5.png'
import img10 from './todos_recursos/netbeans6.png'
import img11 from './todos_recursos/netbeans7.png'
import img12 from './todos_recursos/netbeans8.png'
import img13 from './todos_recursos/doc1.png'
import img14 from './todos_recursos/palabrasReservadas.png'
import img15 from './todos_recursos/declarar_variables.png'
import img16 from './todos_recursos/tiposDato.png'
import img17 from './todos_recursos/DatoReferenciado.png'
import img18 from './todos_recursos/operacion.png'
import img19 from './todos_recursos/operdores.png'
import img20 from './todos_recursos/increm.png'
import img21 from './todos_recursos/opCondicion.png'
import img22 from './todos_recursos/ciudad.png'
import img23 from './todos_recursos/if.png'
import img24 from './todos_recursos/switch.png'
import img25 from './todos_recursos/for.png'
import img26 from './todos_recursos/while.png'
import img27 from './todos_recursos/dowhile.png'
import img28 from './todos_recursos/entradaDatos.png'
import img29 from './todos_recursos/salida.png'
import img30 from './todos_recursos/printf.png'
import img31 from './todos_recursos/try1.png'
import img32 from './todos_recursos/try2.png'
import img33 from './todos_recursos/cubos.png'
import img34 from './todos_recursos/clase_completa.png'
import img35 from './todos_recursos/modificadores.png'
import img36 from './todos_recursos/clase_atributos.png'
import img37 from './todos_recursos/mod_atributos.png'
import img38 from './todos_recursos/clase_constructor.png'
import img39 from './todos_recursos/crear_objetos.png'
import img40 from './todos_recursos/metodo_color.png'
import img41 from './todos_recursos/mod_metodos.png'
import img42 from './todos_recursos/metodo_double.png'
import img43 from './todos_recursos/cuerpo_metodo.png'
import img44 from './todos_recursos/almacen.png'
import img45 from './todos_recursos/Documents-amico.png'


const Tema = (valor) => {
    var ejemplo1= "1. String[] listaPalabras; \nlistaPalabras= new String[10]; \n2. int[] listaNumeros= new int[10]; \n3. listaNumeros[0]=8; \nfor(i=0; i<=9; i++){\nlistaNumeros[i]=100;} \n4. int[] misegundaLista = new int[]{1, 2, 3, 4, 5};"
    var ejemplo2= "ArrayList< String > listaDinamica=new ArrayList< String >();listaDinamica.add(misdatos); //introducir datos \nlistaDinamica.remove(i); //eliminar datos."
    var ejemplo3= "/**\n* Método que nos indicara, mediante booleano, la existencia del archivo.\n* @param rutaAlArchivo\n* @return boolean*/\n public boolean existeArchivo(String rutaAlArchivo) {\n Path path = Paths.get(rutaAlArchivo);\n return Files.exists(path);\n}"
    var ejemplo4= "/**\n*Método que creara un archivo en el directorio de trabajo.\n* @param nombre\n* @throws IOException\n*/\npublic void crearArchivo(String nombre) throws IOException {\nPath path = Paths.get(nombre);\nFiles.createFile(path);\n}"
    var ejemplo5= "/**\n* Método para eliminar un archivo si existe.\n* @param rutaAlArchivo\n* @throws IOException\n*/\npublic boolean borrarArchivo(String rutaAlArchivo) throws IOException {\nPath path = Paths.get(rutaAlArchivo);\nreturn Files.deleteIfExists(path);\n}"
    var ejemplo6= "/**\n* Método que escribe texto procedente una lista en un archivo.\n* @param archivo\n* @param listaDatos\n* @throws IOException\n*/\npublic void escribir(String archivo, String[] listaDatos) throws IOException{\nFileWriter escritor = new FileWriter(archivo);\nfor(int i=0; i< listaDatos.length; i++){ //Escribimos mediante bucle.\nescritor.write(listaDatos{expresionCorchet});\n}\n\n}"
    var ejemplo7= "/**\n* Método que nos imprime el contenido de un fichero local.\n* @param archivo\n* @throws FileNotFoundException, IOException\n*/\npublic static void verDoc(String archivo) throws FileNotFoundException, IOException {\nString cadena;\nFileReader f = new FileReader(archivo);\nBufferedReader b = new BufferedReader(f);\nwhile ((cadena = b.readLine()) != null) {\nSystem.out.println(cadena);\n}\nb.close();\n}"
    var ejemplo8="import java.io.Serializable;\n/**\n*\n* @author alberto\n*/\npublic class PulsarLaTecla implements Serializable{\n/*Atributos del objeto*/\nprivate String temas;\nprivate int notas;\n\n/*Getters y setters*/\npublic String getTemas() {\nreturn temas;\n}\n\npublic void setTemas(String temas)\n{\n\nthis.temas = temas;\n}\n\npublic int getNotas() {\nreturn notas;\n}\n\npublic void setNotas(int notas) {\nthis.notas = notas;\n}\n}"
    var ejemplo9= "FileOutputStream fisal = new FileOutputStream(nuestroFichero.out);\nObjectOutputStream oos = new ObjectOutputStream(fisal);\noos.writeObject(nuestroObjeto);"
    var ejemplo10= "FileInputStream fich = new FileInputStream(nuestroFichero.out);\nObjectInputStream os = new ObjectInputStream(fich);\nPulsarLaTecla nuestroObjeto = (PulsarLaTecla) os.readObject();"
    
    switch(valor){
      case 1: 
      return(
              
              <section class="contenido" id="punto">
  
 
                <h1>¿Qué es la programación?</h1>
                  <p class="pulsar">
  
                  <a href="https://www.freepik.es/vector-gratis/computadora-portatil-icono-isometrico-codigo-programa-desarrollo-software-aplicaciones-programacion-neon-oscuro_4102879.htm#query=neon%20ordenador&position=12&from_view=search&track=sph" target="_blank">
                    <img class="primera_img" src={img} />
                  </a>
  
                  Cuando traté de saber qué era la programación quede bastante decepcionado, solo encontraba explicaciones teóricas y casi matemáticas sobre este asunto, parecía algo pesado,
                  complejo, solo digno de grandes mentes. Pero en el pasado, estudiando electricidad y electromagnetismo, encontré un significado que me cautivo, algo sencillamente apasionante que me
                  traslado a un mundo de ciencia ficción, que solo había sentido viendo las ilustraciones que los dibujantes de cómic plasmaban y de los que soy amante. Aunque sea una difícil tarea,
                  voy a tratar de transmitirte esa sensación, dejaremos las explicaciones teóricas y serias a gente más preparada.<br /> Suponiendo que ya conoces, por lo menos los nombres, de los distintos
                  lenguajes existentes así como sus niveles de abstracción y las distintas dificultades que entraña utilizar uno u otro, sabrás, o por lo menos deberías intuir, que estos "idiomas"
                  se utilizan para decirle a una máquina como debe operar sobre una serie de datos o sobre una serie de acciones o eventos. Si realizamos una búsqueda a través de la red encontramos
                  definiciones muy similares a esta introducción, como la que nos brinda wikipedia.
                  </p>
  
                  <p class="recuadro">
              <i>“Un lenguaje de programación es un lenguaje formal (o artificial, es decir, un lenguaje con reglas gramaticales bien definidas)
              que le proporciona a una persona, en este caso el programador, la capacidad de escribir (o programar) una serie de instrucciones o secuencias de órdenes en forma de algoritmos con el fin de
              controlar el comportamiento físico o lógico de un sistema informático, de manera que se puedan obtener diversas clases de datos o ejecutar determinadas tareas.”</i>
              </p>
  
              <p class="pulsar">
  
              <a href="https://www.freepik.es/vector-gratis/concepto-transformacion-digital-vector-fondo-microchip-tecnologia-ai_16396113.htm#query=neon%20ordenador&position=19&from_view=search&track=sph" target="_blank">
                <img class="segunda_img" src={img1}/>
              </a>
  
              Aquí tenemos algo clave y es
              la palabra <b>lenguaje</b>, estamos comunicándonos con una máquina y ésta lo único que entiende son impulsos eléctricos. Esto ya es increíble, establecer una comunicación con un ser inanimado que solo
              puede saber si recibe un impulso eléctrico o no, pero con esto ya es suficiente. Este es el nacimiento del código binario, el código con el que nos vamos a comunicar con la máquina basado en que
              ella simplemente distinga si recibe impulsos eléctricos o no, y los ordene en unos y ceros. Esta es la raíz de la programación, un hecho sin precedentes, donde los seres humanos hemos logrado
              establecer una comunicación con algo que nosotros mismos hemos creado.
              <br /><br />
              Aprender programación es algo que va mucho más allá de escribir código delante de una pantalla, de tener un cómodo trabajo bien remunerado, o hacer ver a los demás que soy un experto
              tecnológico y un tío a la moda. Vamos a comprender la forma en la que podemos establecer comunicación con una máquina, y aquí tu imaginación es el límite... todo es posible si eres capaz de imaginarlo.
              </p><br /><br />
  
              </section>
              
        )
          break;
  
      case 2:
        return (
      <section class="contenido" id="punto">
  
  
                <h1>¿Qué es Java?</h1>
  
  
                <p class= "pulsar">
                <img class="imagen1" src={img0} width="30%"/>
                Vamos a hablar de Java, un lenguaje creado por ingenieros de Sun Microsistems, allá por 1991, debido a la necesidad de crear un lenguaje que fuera independiente del dispositivo donde estuviera.
                Comenzaremos haciendo una breve descripción sobre este lenguaje y poco a poco nos iremos introduciendo en como utilizar este para que nos ayude
                en nuestros fines. La principal ventaja que tenemos con Java es su independencia del hardware, ya que el código resultara valido para cualquier plataforma gracias a la maquina virtual de
                Java. Otra de las grandes ventajas de este lenguaje es su programación orientada a objetos o POO, iremos introduciéndonos en esta manera de programar e iréis viendo claro este
                concepto que no es más que una abstracción. Es decir es una manera de volver el código más "humano" o acercarlo a nuestra manera de pensar y ver el mundo, puede sonar algo complejo pero nada
                nada mas lejos de la realidad, vamos a poner un pequeño ejemplo parea verlo más claro. Imaginemos queremos crear un programa que nos ordene los emails, queremos que el programa simplemente
                haga de "archivador" y los tenga ordenados en función de quien nos los manda, es decir es como si clasificáramos el correo postal por remitentes en uno de aquellos viejos archivadores de
                hierro tan típicos en las oficinas. En este punto ya nos hemos abstraído y hemos imaginado una acción real sobre lo que queremos que haga el programa, de esta manera es como nos ayuda
                la POO a trasladar acciones reales al código. La POO nos permite crear ese archivador, estructurar sus cajones, su forma, que correo aceptara y cual no, acercando la manera de programar
                hacia el pensamiento humano.<br />
                Otro característica que ha impulsado a Java a ser uno de los lenguajes más usados es su capacidad de crear aplicaciones que trabajan en red ya que mediante sus librerías podemos
                interactuar con protocolos como http o ftp.
                </p>
  
  
                <h3>Bytecodes</h3>
  
                <p class="pulsar">
                El código escrito en Java no es directamente ejecutable, ya que se trata de un lenguaje que ha de ser primeramente interpretado por la maquina virtual de java. Una vez tenemos creado el código
                éste es precompilado, transformándose en lo que se conoce como bytecodes, estos bytecodes serán interpretados por la maquina virtual de java y se traducirán al código nativo de la máquina
                sobre la que estemos trabajando, de ahí su independencia con el hardware.
                </p>
  
                <h3>¡Quiero programar!</h3>
  
                <p class="pulsar">
                Sí, sí, ya vamos a ello, aunque las prisas no son buenas cuando queremos hacer un buen trabajo. Para comenzar a programar necesitamos montar nuestro <b>entorno de desarrollo Java</b>. Oracle
                pone a nuestra disposición este entorno de trabajo llamado JDK (Java Development Kit) compuesto por un compilador y un interprete para línea de comandos, además de una implementación del entorno
                de ejecución de Java el JRE (Java Runtime Environment) donde se encuentra la máquina virtual de Java. Existen más entornos de desarrollo, que contendrán más o menos herramientas según
                nuestras necesidades, pero recomendamos JDK de Oracle debido a su facilidad, su extensión y que se trata de un programa gratuito, también es muy interesante usar la versión libre OpenJDK,
                ambas tendrán la misma utilidad. Cabe destacar que junto al JDK viene incluidas todas las bibliotecas de clases API que son pequeñas clases creadas que podremos usar en nuestro trabajo
                para ahorrar tiempo y líneas de código.
                </p>
                <a class="logo" href="https://www.oracle.com/java/technologies/downloads/" target="_blank"><div class="button" id ="alineado">JDK oracle</div></a>
                <a class="logo" href="https://openjdk.java.net/install/" target="_blank"><div class="button" id ="alineado">Open JDK</div></a>
  
  
                <p class="pulsar">
                Vale, vale, tranquilo, se que has ido como loco a empezar las descargas pero no tienes todo lo que necesitas. Aunque podrías comenzar a programar en ficheros de texto plano sería una autentica
                locura tratar de aprender así con las herramientas que tenemos hoy en día. Aparte del JDK necesitaremos un programa que nos guíe y ayude mientras escribimos código y nos asista para
                encontrar errores léxicos y lógicos, necesitamos un Entorno Integrado de Desarrollo (IDE). Para el lenguaje de programación Java disponemos de múltiples IDEs, pero para facilitar las
                explicaciones durante todo el curso usaremos el IDE Netbeans, que además de ser gratuito cuenta con el apoyo de Sun microsystems.
                </p>
  
                <a class="logo" href="https://netbeans.apache.org/download/index.html" target="_blank"><div class="button" id ="alineadoS">Netbeans</div></a>
  
  
                <p class= "pulsar">
                Muy bien, parece que ya tenemos las herramientas necesarias para comenzar a escribir algo de código, no obstante la instalación y configuración de los paquetes y programas mencionados
                necesitaran ser configurados correctamente indicando donde esta el JDK que usaran. También podemos configurar la gama de colores y el aspecto del interfaz a nuestro gusto.
                </p>
  
                <h3>¿Qué podemos crear con Java?</h3>
  
                <p class= "recuadro">
                Una vez tenemos armado nuestro entorno de desarrollo disponemos de lo necesario para comenzar a crear... ¿Qué vamos a crear? Parece que esta es la primera cuestión, es completamente necesario
                que sepamos lo que vamos a construir, siempre debemos ser conscientes de lo que hacemos y cómo lo hacemos. La potencia de este lenguaje ofrece al programador crear aplicaciones muy
                variadas, vamos a verlas:
                </p>
  
                <h4>Aplicaciones de consola:</h4><hr />
                <p class= "pulsar">
                Son programas independientes, como cualquier programa que imagines, pero estas aplicaciones carecen de interfaz gráfica así que leen y escriben a través de línea de comandos
                </p>
                <h4>Aplicaciones gráficas:</h4><hr />
                <p class= "pulsar">
                Igualmente son programas independientes pero esta vez sí disponen de una interfaz gráfica, para la creación de estos programas se utilizan bibliotecas y clases avanzadas. Son los programas
                tradicionales con sus botoncitos y dibujitos donde ya no utilizaremos línea de comandos para comunicarnos con nuestro programa.
                </p>
                <h4>Applets:</h4><hr />
                <p class= "pulsar">
                Estos son programas incrustados en otras aplicaciones, como un juego dentro de una web, nuestro navegador carga la web y ejecuta el programa del juego. Estos programas no suelen tener acceso
                a partes sensibles de la memoria y tiene ciertas limitaciones, debido a que se ejecutan automáticamente el usuario deberá dar ciertos permisos para que estos programas puedan leer
                y escribir. Estos programas carecen de un método principal y evidentemente son multiplataforma.
                </p>
                <h4>Servlets:</h4><hr />
                <p class= "pulsar">
                Estos programas lo que hacen es ampliar las capacidades de un servidor. Traduciendo... es un programa que estaría del lado del servidor y daría respuestas a las peticiones de los usuarios,
                generando webs dinámicas como respuesta en función de la información o demanda del usuario.
                </p>
                <h4>Midlests:</h4><hr />
                <p class= "pulsar" id="ult">
                Son aplicaciones creadas para su aplicación en dispositivos simples. Para muestra un botón, los juegos Java creados para móviles son Midlests. <br />
                Parece que ya tenemos nuestra máquina lista y hemos asentado los primeros conceptos.
                </p>
  
  
      </section>
        )
        break;
        case 3:
          return (
            <section class="contenido" id="punto">
  
  
              <h1>Comenzando a programar</h1>
  
              <p class= "pulsar">
                <a href="https://www.freepik.es/foto-gratis/luces-urbanas-misteriosas-estetica-cinematografica_18407457.htm#query=neon%20ciudad&position=13&from_view=search&track=sph" target="_blank">
                  <img class="primera_img" src={img3} width="33%"/>
                </a>
  
              Antes de comenzar a realizar cualquier tipo de programación es importante que sepamos como trasladar las ideas de nuestra cabeza al ordenador en forma de código. No es muy recomendable
              programar sin tener clara una estructura de lo que queremos hacer, esto nos llevara a tener que rehacer muchas partes de código una y otra vez por cosas que no hemos tenido en cuenta.
              Al principio es normal detectar errores que te obligan a reescribir partes del código y también este hecho nos ayuda a practicar y encontrar nuevos caminos. Pero conforme
              maduremos nuestra manera de programar querremos centrarnos en el proyecto y no estar perdiendo el tiempo en reescribir código una y otra vez, esto al final es odioso. En la
              programación profesional existen protocolos para planificar el trabajo y minimizar todos estos errores, realizando una serie de análisis, como son las pruebas de caja blanca y las
              pruebas de caja negra. En este tipo de pruebas nos introduciremos más adelante, ahora lo que principalmente vamos a hacer es utilizar el <b>sentido común</b>:
              </p>
  
                  <ul class= "pulsar" id="lista">
                      <li>No te pongas a programar sin más, coge una libreta y estructura tu programa, sus secciones, sus acciones y sus objetivos. Divide tu programa en pequeñas partes o secciones
                      y trabájalas por separado, así evitaras quedarte bloqueado programando o sentirte perdido en tu propio código.</li><br />
                      <li>Documenta tu código usando comentarios, explica los métodos y su retorno, documenta las clases y escribe siempre los porqués cuando uses bucles o condicionales. Esto
                      es muy importante para entender tu código y poder utilizar en un futuro partes de este al implementar otros programas. Esta es una de las ventajas que tiene la programación
                      con Java y créeme que la vas a usar.</li><br />
                      <li>Crea copias de seguridad de tu trabajo y ten mucho ojo cuando modifiques partes importantes del código, correrás el riesgo de perder todo el trabajo. Por experiencia
                      propia recomendaría también trabajar con dos discos duros, uno con los datos importantes y otro con el SO. Así ante la necesidad de recuperar el sistema por un grave error
                      nunca perderás los datos ni tu trabajo. En las secciones de Java avanzado explicamos como trabajar con repositorios y control de versiones.</li>
                  </ul>
  
              <p class= "pulsar">
              Algo muy importante que nos evitara perder infinidad de horas delante del pc, así como una cascada de estrés y frustración, es aprender a gestionar los errores. Al programar veremos
              como nuestro IDE nos notifica errores, sobre todo gramaticales, que no nos permitirán compilar o hacer funcionar correctamente nuestro programa. Bien, en este punto es importante:
              </p>
  
  
                  <ul class= "pulsar" id="lista">
                      <li>Prestar la máxima atención a lo que nos dice el IDE. Si no controláis mucho de ingles (la mayoría de IDEs nos hablaran en esta lengua) no dudes en traducir todo lo que
                      este te indique sobre el error, para así comprenderlo y subsanarlo. Ojo debemos comprender en que nos equivocamos para aprender de ello.
                      </li>
                  </ul>
  
                      <img class="error" src={img4} width="80%"/>
                      <p class="piefoto">Error gramatical en Netbeans</p><br />
  
                  <ul class= "pulsar" id="lista">
                      <li>Habrá ocasiones donde el IDE no sea tan concreto como nos gustaría y nos da "pistas" sobre el error. En estos casos no desesperemos, poco a poco iremos ganando la
                      destreza suficiente para entender mejor a nuestro IDE.</li><br />
                      <li> Cuando nos encontremos frente a errores en la lógica, es decir el programa compila pero falla en determinado punto o no nos devuelve el valor esperado,
                      debemos encuadrar la parte del código que falla para trabajarla, normalmente mediante un debug. Por norma general el IDE nos dará un mensaje de error indicándonos más o menos a que se debe,
                      como ya sabéis máxima atención a esto.</li><br />
  
                      <li> En los momentos donde no seamos capaces de encontrar el error, estén pasando las horas y subiendo los niveles de estrés, es mejor dejarlo y relajarse. Por experiencia
                      en la mayoría de estos casos tendremos el error delante de nuestras narices y no lo veremos. <b>Desconectar un rato suele ayudarnos a tener más amplitud mental.</b> </li><br />
  
                      <li>Algo que nos va a volver locos y muchas veces nos alejara de nuestro camino es intentar encontrar la fuente de nuestros errores buscando por internet. Esto es una
                      autentica locura si no sabemos concretamente que buscar. La programación tiene muchos caminos, es como si tuviéramos que llegar al numero 99 mediante operaciones, veras
                      que existen multitud de maneras de llegar a ese numero, pues esto es lo mismo. La mayoría de las soluciones que hallemos no las vamos a comprender, y las que comprendamos
                      posiblemente nos obliguen a reescribir parte del programa perdiendo mucho tiempo. Por otro lado cabe destacar que por internet vamos a encontrar pocas explicaciones o
                      soluciones profesionales, la mayoría de veces encontraremos parcheados o códigos larguísimos que van a estar muy lejos de ayudarnos. Debemos ser capaces de encontrar nuestras
                      propias soluciones comprendiendo las características del lenguaje.</li>
                  </ul>
  
              <p class= "pulsar">
              Otro motivo que lastra nuestro aprendizaje y nos aleja de la comprensión es nuestra manera de escribir código y como hemos llegado a el. Me explico:
              </p>
  
              <ul class= "pulsar" id="lista">
                  <li>No recomendamos, en esta etapa del aprendizaje, copiar y pegar ninguna parte del código por muy simple que sea. Escribir el código nos acerca a su comprensión y memorización.</li><br />
                  <li>También debes saber como has llegado a ese código, comprendiendo bien los métodos y clases que estas utilizando sobre todo cuando sean de la biblioteca. Hablamos de algo en la
                  misma línea cuando hablábamos sobre "encontrar nuestras propias soluciones", es decir lo que necesitamos es comprender las acciones del código. No intentes encontrar soluciones
                  que te permitan continuar con el programa sin comprender como has sorteado el problema.</li><br />
                  <li>Trata de escribir un código claro, usa tabulaciones y saltos de línea de manera que pueda verse de la manera más clara posible el flujo del programa.</li><br />
              </ul>
  
              <p class="pulsar" id="ult">
              Estos conceptos nos ayudaran en esta etapa de nuestro aprendizaje, parece que ya estamos preparados para continuar...
              </p>
  
        </section>
          )
          break;
      case 4:
          return(
      <section class="contenido" id="punto">
  
              <h1>Configuración y uso de Netbeans</h1>
  
              <p class= "pulsar">
              En la primera sección del curso, donde hemos preparado nuestra máquina, disponéis de todo lo necesario para instalar Netbeans en vuestro pc con sus respectivos tutoriales.
              Durante el curso utilizaremos Netbeans como IDE para crear nuestros programas y recomendamos que utilicéis el mismo IDE para hacer más fácil el seguimiento del curso. Este fue el
              primer IDE programado con lenguaje Java y posteriormente a su salida fue comprado por Sun Microsistems, quien decidió que el programa seria un software libre y actualmente en el
              proyecto participan muchas empresas impulsando su desarrollo. Vamos a ver en detalle que nos ofrece Netbeans y como podemos utilizarlo y entenderlo.<br /><br />
              Cuando iniciemos Netbeans nos encontraremos la siguiente ventana:
              </p>
  
              <img class="netbeans" src={img5} width="100%"/>
  
              <h3>Proyectos</h3>
  
              <p class="pulsar">
              En esta zona veremos nuestros proyectos creados y que estén guardados en el directorio de trabajo. El directorio es una carpeta que creara automáticamente Netbeans y almacenara nuestros
              proyectos. Indicar que es importante crear copias de seguridad de estos proyectos, estos están en forma de carpetas las cuales podremos comprimir y almacenar como copia
              de seguridad. El código creado se encuentra dentro de la carpeta src.
              </p>
  
              <h3>Output</h3>
  
              <p class="pulsar">
              Aquí es donde el programa se comunicara con nosotros. Nos informara de fallos, errores o cualquier evento importante.
              </p>
  
              <h3>Editor</h3>
  
              <p class="pulsar">
              En este recuadro es donde escribiremos el código como si de un editor de texto se tratase. En la imagen vemos una pequeña pagina de inicio que se muestra al iniciar el programa que dará
              paso al editor en cuanto creemos o abramos un proyecto.
              </p>
  
              <img class="netbeans" src={img6} width="100%"/>
  
              <p class="pulsar">
              En la imagen anterior podemos observar el código del programa en la zona del editor. Es un programa que tiene un objeto, la cuenta, y dos métodos, ingresar y retirar. Si lo ejecutamos
              el output nos da la salida que se aprecia en la imagen, puesto que es lo que le hemos pedido al programa mostrando el estado de los objetos.
              </p>
  
              <p class="pulsar">
              Ahora ya tenemos una idea sobre lo que vemos cuando iniciamos Netbeans, es el momento de crear un proyecto.
              </p>
  
  
              <h3>Creando un proyecto</h3>
  
              <p class="pulsar">
              Vamos a realizar los pasos necesarios para crear un proyecto y explicaremos algunas opciones disponibles en su creación.
              </p>
  
              <img class="netbeans" src={img7} width="60%"/>
  
              <p class="pulsar">
              Para crear el proyecto pulsaremos sobre el icono marcado, también podríamos abrir la pestaña file y seleccionar new proyect. A continuación nos encontraremos con la siguiente imagen.
              </p>
  
              <img class="netbeans" src={img8} width="65%"/>
  
              <p class="pulsar">
              En "categories" vemos que nos ofrece distintas opciones en función del lenguaje con el que vayamos a programar, nos centraremos en las tres primeras opciones para el lenguaje Java.
              Maven, Gradle y Ant son herramientas para la automatización en la compilación. En esta etapa del aprendizaje utilizaremos Ant y seleccionaremos en projects "Java application", más
              adelante entraremos en detalle sobre estas herramientas de automatización y como pueden influir en nuestros proyectos, ya que se trata de cuestiones mas avanzadas. Pulsamos en
              next para pasar a la siguiente ventana.
              </p>
  
              <img class="netbeans" src={img9} width="65%"/>
  
              <p class="pulsar">
              En este paso nos solicitara el nombre de nuestro proyecto, su ubicación, que por defecto será el directorio de trabajo creado por Netbeans, y el nombre de la carpeta que contendrá
              nuestro trabajo. Solo necesitaremos poner el nombre de nuestro proyecto y listo, las otras dos opciones (location y folder) las podemos dejar por defecto.<br /><br />
              Las siguiente dos casillas de verificación ("use dedicate folder" y "create main class") se refieren respectivamente a indicarle a Netbeans la ruta hacia una serie de bibliotecas a incluir
              en el proyecto de manera que formen parte de el, es decir podemos indicarle mientras programamos que use "X" biblioteca externa que tenemos en nuestro disco. Lo malo es que si nos
              llevamos el proyecto a otro pc y lo ejecutamos no va a encontrar esa biblioteca que teníamos en el pc donde programamos. Con esta opción evitamos este problema ya que la biblioteca
              pasa a formar parte del proyecto.<br /><br />
              La otra casilla de verificación se refiere a que nos cree automáticamente la clase main, que es necesaria para la ejecución del programa. En esta etapa del aprendizaje no seleccionaremos
              esta opción pues la crearemos manualmente. Recordad que es necesario escribir el código para comprenderlo y memorizarlo, si empezamos ya seleccionando atajos nunca sabremos crear
              esta clase tan importante. Pulsamos "finish" para finalizar la creación.
              </p>
  
              <img class="netbeans" src={img10} width="55%"/>
  
              <p class="pulsar">
              Una vez creado nuestro proyecto necesitamos crear un paquete que contendrá las clases, mas adelante analizaremos en profundidad como distribuir clases y paquetes y como afecta esto
              a nuestro programa. Solo debemos poner el nombre del paquete y pulsar "finish". Ahora necesitamos crear una clase donde ya podamos empezar a editar código.
              </p>
  
              <img class="netbeans"  src={img11} width="55%"/>
  
              <p class="pulsar">
              Como veis usando el menú contextual encima del paquete que terminamos de crear tenemos la opción de crear una clase. Vemos dos opciones "Java class" y "Java main class" es la
              misma opción que nos daba en el segundo paso, donde nos crea la clase main automáticamente. En nuestro caso solo crearemos la clase, así aprenderemos el código de la clase main.<br />
              Realizando todos los pasos anteriores ya tendríamos creado nuestro proyecto.
              </p>
  
              <h3>Compilar y ejecutar</h3>
  
              <p class="pulsar">
              Una vez tengamos un código escrito en el editor deberemos compilarlo y ejecutarlo para comprobar su funcionamiento.
              </p>
  
              <img class="netbeans" src={img12} width="70%"/>
  
              <p class="pulsar">
              Vamos a ver respectivamente que acciones van a realizar sobre nuestro código los iconos marcados:
              </p>
  
              <ul class="pulsar" id="lista">
                  <li>Este icono nos permite comenzar la compilación de nuestro código, es decir comenzamos su tradición al lenguaje máquina.</li><br />
                  <li>Este segundo icono limpiara los archivos creados de una compilación anterior y volverá a compilar desde cero.</li><br />
                  <li>Este ultimo botón ejecutara el programa. Para que el programa pueda ejecutarse no deben haber errores durante la compilación y este deberá disponer de una clase main,
                  es donde estará el flujo del programa y es necesaria para la ejecución. También podemos hacer click con el menú contextual sobre la clase main y seleccionar "run".</li>
                  <br /><br /><br /><br />
              </ul>
  
  
  
      </section>
          )
          break;
      case 5:
        return(
      <section class="contenido" id="punto">
  
            <h1>Documentar el código</h1>
  
            <p class="pulsar">
            Documentar el código es algo completamente necesario. Por un lado es muy importante cuando trabajan en la construcción de un programa varias personas, así todos pueden entender
            el trabajo realizado por los compañeros. Además de que la mayoría de programas a nivel profesional tiene un mantenimiento y se mejora el código con versiones nuevas, sin estas
            anotaciones el mantenimiento de un programa seria un autentico caos.<br /><br />
            Por otro lado su importancia radica en el entendimiento de nuestro propio código y la posibilidad de reutilizarlo. Por estos motivos es tan
            importante documentar nuestro código explicando sus acciones y decisiones. Si no lo hacemos nos encontraremos muchas partes de código que no recordaremos exactamente para que las
            implementamos y cual es su acción dentro del programa.
            </p>
  
            <img class="documentacion" src={img13} width="60%"/>
  
            <p class="pulsar">
            En la imagen de arriba hemos marcado los comentarios, como veras son mensajes que dejamos los cuales no serán interpretados como código y por tanto compilados. Son descripciones que
            dejamos sobre el código para poder entender correctamente su función. Existen tres maneras de comentar el código:
            </p>
  
            <ul class="pulsar" id="lista">
                <li>Comentarios cortos o de una línea. Utilizando el delimitador // podremos introducir comentarios cortos.</li><br />
                <li>Comentarios de múltiples líneas. Utilizando el delimitador /* */ podremos introducir ilimitadas líneas de comentarios. Es la manera en la que se han introducido los tres
                últimos comentarios de la imagen.</li><br />
                <li>Comentarios Javadoc. Utilizando el delimitador /** */ introduciremos comentarios que podrán ser usados para la generación automática de documentación del programa.
                Mediante el programa Javadoc se recogerán todos estos comentarios y se generara un documento HTML. Correspondería con los dos primeros comentarios de la imagen.</li><br />
            </ul>
  
            <p class="pulsar" id="ult">
            Aunque, en principio, este pequeño apartado sobre los comentarios pueda parecer algo absurdo, en un futuro nos será de gran ayuda. Por este motivo comenzar a programar teniendo la
            costumbre de comentar nos ayudara a crear código ordenado y claro.
            </p>
  
  
      </section>
      
        )
        break;
      case 6:
        return(
      <section class="contenido" id="punto">
  
              <h1>Variables e identificadores</h1>
  
              <p class="pulsar">
              Vamos a dar una definición lo más clara y concisa posible a estos dos conceptos. También os brindamos un vídeo para que veáis cual es su función dentro de un programa, pensamos que
              esto os ayudara a comprender perfectamente variables e identificadores.
              </p>
  
              <h3>Variables</h3>
  
              <p class="pulsar">
              Como definición más técnica podríamos decir que una variable es una zona de memoria de nuestro ordenador que se reserva para almacenar un dato que será usado más tarde por el programa. Ahora
              vamos a explicar la explicación, ya que cuando uno no ha programado nunca, esta definición le deja exactamente igual. Esencialmente una variable dentro del programa lo que hará
              será almacenar un dato que estará tipado, debemos indicar qué tipo de dato almacenaremos. Este dato será el resultado de algo, como preguntar al usuario del programa su nombre.
              Aquí ya tenemos el dato y en este caso sería una cadena de caracteres, también podríamos almacenar su edad que sería un dato de numero entero. Las variables tiene principalmente esa función
              son transportadoras de datos y así es como las usaremos.<br /><br />
  
              También tenemos como un tipo de variable, mas concretamente llamada variable final, que son variables cuyo valor no variara en toda la ejecución del programa. Más conocidas como constantes.<br /><br />
  
              Cabe añadir que según el uso que le demos a la variable podremos identificarla de dos maneras.
              </p>
  
              <ul class="pulsar" id="lista">
                  <li>Variables miembro: Variables que estarían dentro de una clase, pero fuera de los métodos.</li><br />
                  <li>Variables locales: Se crean y usan dentro de un método.</li>
              </ul>
  
  
  
  
              <h3>Identificadores</h3>
  
              <p class="pulsar">
              Los identificadores serán los nombres que pondremos a las variables. En matemáticas conocemos las típicas variables X e Y, imagina que dentro de una ecuación que nos dice el gasto total de
              combustible de nuestro vehículo, la variable X representa el combustible e Y representa los kilómetros recorridos. Si usáramos identificadores en vez de tener X e Y, directamente
              pondríamos "combustible" y "kilómetros", de esta manera es mucho más ilustrativo e intuitivo. Ten en cuenta que dentro de un programa podemos trabajar con cientos de variables, así que identificarlas es algo
              esencial. Cuando usamos un identificador debemos tener en cuenta una lista de palabras reservadas por el programa que identifican ciertas acciones y no podemos usarlas.
              </p>
              <img class="tabla" src={img14} width="70%"/>
  
              <h3>Declaración e inicialización</h3>
  
              <p class="pulsar">
              Las variables deben ser declaradas indicando el tipo de dato y también es recomendable inicializarlas siempre, es decir darle algún valor a la variable. Nos encontraremos en muchas ocasiones
              que el compilador nos solicitara inicializar la variable, es decir darle un valor. Esto es debido a que la introducimos en el flujo del programa y no le hemos dado un valor, como esto puede
              ser un error, el compilador nos indica que la inicialicemos.
              </p>
  
              <img class="tabla" src={img15} width="55%"/>
  
              <p class="pulsar" id="ult">
              No es obligatorio inicializarlas al declararlas pero si no lo hacemos el compilador le dará un valor por defecto y eso puede generarnos problemas. Utiliza las variables, tanto declaración como
              inicialización, de la manera que más cómodo te encuentres o pienses que el código se ve más claro, esto dependerá de ti pero ten en cuenta lo comentado.
              </p>
  
      </section>
        )
        break;
        case 7:
          return(
      <section class="contenido" id="punto">
  
                <h1>Tipos de datos</h1>
  
                <p class="pulsar">
                El lenguaje Java es una lenguaje fuertemente tipado, esto nos señala que debemos indicar qué tipo de dato se va a almacenar en la variable. Como explicamos anteriormente, cuando necesitamos usar
                una variable, la cual recogerá un dato, es completamente imprescindible seleccionar el tipo de dato a almacenar. Resumiendo, tenemos que indicar si va a guardar un numero, y en este caso que tipo de numero
                como puede ser un entero o un decimal, un carácter (una sola letra), una cadena de caracteres (una palabra), un dato en forma de objeto... Os dejamos por aquí una lista de los datos
                que tenemos en Java y un vídeo para que veáis como se usan en la práctica.<br /><br />
                La tabla de abajo recoge lo que se conoce como datos primitivos en Java.
                </p>
  
                <img class="tabla" src={img16} width="70%"/>
  
                <p class="pulsar">
                Estos ocho datos expuestos en la tabla son los existentes en Java, pero hay algo que suscita mucha confusión, los datos de tipo referenciados. Las variables de datos referenciados no almacenan
                ningún dato, si no que nos permitirían acceder a métodos y atributos de un objeto. Así que utilizando las clases que ya existen en java encontraremos multitud de objetos y los usaremos de
                forma muy similar a los tipos de datos. También es importante
                comentar que las listas pertenecen a este tipo de datos, más adelante entraremos en profundidad sobre como usarlas. Dejamos una tabla como ejemplo de como declarar algunos de
                los tipos referenciados más usados.
                </p>
  
                <img class="tabla" src={img17} width="70%"/>
  
                <p class="pulsar">
                En la tabla de arriba lo que se esta haciendo es crear objetos que nos permitan manejar los datos de la forma que queremos. En las dos primeras declaraciones se esta creando un objeto
                cuyo identificador es miFecha y miHora respectivamente. Después se utiliza uno de los métodos de la clase (LocalDate.now() y LocalTime.now())para obtener la fecha y la hora del reloj
                del sistema. Por lo tanto para manejar cierto tipo de datos vamos a tener que recurrir a estas APIs de Java, creando los objetos y estudiando la clase y sus métodos para ver que nos ofrece.
                Las otras dos declaraciones son más simples aunque también se trata de objetos pero en este caso Java nos permite declararlas de manera más fácil. Primero hemos declarado una cadena de
                caracteres donde se almacenara texto. La segunda declaración es un objeto tipo lista que almacenara cuatro valores de números enteros, el cero es un numero y por tanto una posición en
                la lista de ahí que acepte cuatro valores.
                </p>
  
                <h3>Conclusión</h3>
  
                <p class="pulsar" id="ult">
                Java solo maneja ocho tipos de datos, pero cuando programemos necesitaremos manejar datos muy específicos para lo que usaremos objetos. Se asemejan a la utilización de variables pero estos
                nos ofrecerán muchas más acciones sobre los datos. Es difícil comprender ciertos aspectos sobre esto cuando aún no se ha programado ni entendido siquiera la utilización de los objetos, en
                esta etapa del aprendizaje necesitaremos mucha paciencia y es normal sentirse algo perdido ya que no comprendemos la totalidad de lo que hacemos. Tened paciencia y poco a poco iréis viéndolo
                más claro.
                </p>
  
  
  
      </section>
          )
          break;
          case 8:
            return(
      <section class="contenido" id="punto">
  
                <h1>Operadores y expresiones</h1>
  
                <p class="pulsar">
                Los operadores llevan a cabo operaciones sobre el conjunto de datos y las expresiones son una combinación de operadores y operandos. Estamos hablando de realizar operaciones matemáticas
                y lógicas sobre los datos. Almacenando los resultados en una variable tendríamos lo que se conoce como sentencia o instrucción. Ten en cuenta que debes almacenar el resultado de la expresión
                (operación) en una variable, de otro modo no tiene ningún sentido hacer esto.
                </p>
  
                <img class="cabecera" src={img18} width="45%"/>
  
                <p class="pulsar">
                Observamos como Netbeans nos marca la primera operación en rojo como un error. En texto nos indica que "not a statement", es decir que no es una declaración. Obvio pues no estamos usando
                una variable para almacenar el resultado, por tanto no estamos declarando nada. En cambio si lo asignamos a la variable "suma" ya tiene un sentido lo que estamos haciendo y Netbeans
                no lo identifica como error.<br /><br />
                Dicho esto dejamos tabla con los operadores que encontramos en Java. Encontraremos operadores lógicos que se usaran en bucles, condiciones, casos... Y operadores matemáticos como venimos
                acostumbrados a trabajar en operaciones matemáticas, ahora algunos nos dan opciones más amplias.
                </p>
  
                <img class="tabla" src={img19} width="75%"/>
  
                <p class="pulsar">
                Los últimos cuatro operadores son conocidos como operadores de asignación. Veréis que al lado de su expresión en Java hay una operación entre paréntesis resaltada en rojo, es la operación
                equivalente. Nos guardara directamente en la variable la operación entre ambos, de esta manera el código es más claro y ágil.
                </p>
  
                <img class="tabla" src={img20} width="75%"/>
  
                <p class="pulsar">
                En la imagen de arriba observamos los operadores incrementales, los cuales tiene dos usos según en que lado de la variable pongamos los signos. Habitualmente los operadores incrementales
                nos servirán para contar repeticiones, aunque disponen de muchos más usos.
                </p>
  
                <img class="tabla" src={img21} width="75%"/>
  
                <p class="pulsar" id="ult">
                Por ultimo vemos los operadores de relación y el operador condicional. Hemos realizado la tabla en forma de pregunta pues parece más fácil de entender. Cabe destacar que como resultado
                de estas preguntas siempre se nos devolverá un valor booleano como verdadero o falso. En cambio si utilizamos el operador condicional, el primero de la tabla, sí podremos indicar que
                queremos que nos devuelva si se cumple la condición o no.
                </p>
  
      </section>
            )
            break;
            case 9:
              return(
      <section class="contenido" id="punto">
  
                <h1>Bucles y estructuras</h1>
  
  
                <p class="pulsar">
                  <a href="https://www.freepik.es/foto-gratis/vista-nocturna-puerto-victoria-hong-kong_19075421.htm#query=neon%20ciudad&position=2&from_view=search&track=sph" target="_blank">
                  <img class="img" src={img22} />
                  </a>
                En este tema vamos a estudiar los bucles y estructuras. Las estructuras es lo que permite al programa "tomar decisiones" mediante sentencias especiales de decisión que nos enviaran a un
                conjunto de secuencias de instrucciones u otro, para esto vamos a utilizar los condicionales estudiados en el tema anterior. Los bucles son estructuras que repetirán una y otra vez un
                bloque de código hasta que se cumpla una determinada condición.<br />
                El funcionamiento de esto es sencillo, crearemos bifurcaciones en el flujo del programa en función de las condiciones que nosotros mismos estableceremos, así es como haremos que nuestros
                programas tomen decisiones aunque en realidad estas decisiones las estaremos tomando nosotros.<br /><br /> ¡Analicemos las estructuras!
                </p>
  
                <h3>Estructura if / if-else</h3>
  
                <p class="pulsar">
                La estructura if es una estructura de selección o estructura condicional, en la que se evalúa una expresión lógica y en función del resultado se ejecuta un bloque de código u otro, o
                simplemente no se hará nada. Lo mejor es que traduzcáis esta palabra "if" que significa "si", no olvidemos que el código es un lenguaje. Por tanto si unimos esto a lo estudiado en
                el tema anterior...
                </p>
  
                <img class="foto" src={img23} width="60%"/>
  
  
                <p class="pulsar">
                Su traducción sería "si miNumero es exactamente igual a 2" y se ejecutaría el código que metamos entre las llaves. En caso de no ser
                igual a 2 la condición seria falsa y no se ejecutaría dicho código. Pero si añadimos un "else" que en traducción podríamos decir que es "de otro modo" si no se cumple nuestra condición
                ejecutaría el contenido del "else". Con esto estaríamos diciéndole al sistema "si miNumero es exactamente igual a 2" con el if, y "de otro modo" con el else. Ya tenemos una decisión que
                controlaremos mediante la condición, donde se ejecutara uno de los dos códigos que ponemos entre llaves. Mencionar que puede haber más de un else recogiendo varias situaciones, pero
                existen otras estructuras más adecuadas para estos casos. Un uso muy común es utilizar siempre el if con el else y dejar el else vacío si no queremos que haga nada si no se cumple la
                condición, es una manera de representar en el código que se ha tenido en cuenta qué hacer si no se cumple la condición.
                </p>
  
                <h3>Estructura switch</h3>
  
                <p class="pulsar">
                Esta estructura es la adecuada cuando tenemos más de dos opciones, esta es una estructura de selección múltiple. La traducción ya es más técnica pues entendemos hace referencia al
                conmutador, el cual es capaz de enviar la corriente hacia un lado u otro.
                </p>
  
                <img class="foto" src={img24} width="60%"/>
  
                <p class="pulsar">
                En la imagen vemos como primero se declaran dos variables, una para números enteros (la cual inicializamos pues no es posible introducirla en la estructura sin un valor) y otra para
                cadenas de texto. En la estructura switch ponemos entre paréntesis la variable que deseamos evaluar y a continuación entre llaves pondremos los casos "case".
                Al lado de cada "case" pondremos el valor que debe tener la variable para ejecutar el caso. Por tanto si la variable vale 10 almacenaremos negro en la variable color y así sucesivamente.
                Podemos añadir al final la palabra "default" la cual recogerá qué hacer si no se cumple ningún caso.
                </p>
  
                <h3>Bucle for</h3>
  
                <p class="pulsar">
                Este es un bucle controlado por un contador, se repetirá un numero limitado de veces. Estos bucles se suelen utilizar para recorrer listas, realizar cálculos, búsquedas...
                </p>
  
                <img class="foto" src={img25} width="50%"/>
  
                <p class="pulsar">
                La estructura del for viene siendo algo así, "desde que i es igual a cero y mientras que i sea menor o igual a 10 incrementando una unidad en cada iteración (recordad que ++ incrementa
                una unidad la variable) se ejecuta el siguiente código". Es decir <b>for(inicio, condición, incremento)</b>, así hemos implementado que realice la operación <b>oper= oper*2</b> un total
                de 12 veces, y después le hemos pedido que nos muestre el resultado que podéis ver en el output. Ojo con que la operación se repite 12 veces, pues el cero es un número, y cuando estamos en
                la iteración 11 se sigue cumpliendo la condición pues es igual a 10 y aún dará una vuelta más. Tened claro este concepto puesto que si tenéis dudas os llevara a varios fallos al usar este bucle.
                </p>
  
                <h3>Bucle for-in</h3>
  
                <p class="pulsar">
                Esta estructura también se considera controlada por contador. Este tipo de bucles nos permitirán hacer recorridos sobre listas y colecciones de objetos. No vamos a entrar a explicar esta estructura
                ya que en este punto del aprendizaje resultaría demasiado complejo hacerse una idea, mas adelante la explicaremos en profundidad.
                </p>
  
                <h3>Bucle while</h3>
  
                <p class="pulsar">
                While es una estructura de repetición controlada por sucesos. La mejor manera de entenderlo es imaginarnos dentro de un menú donde seleccionamos opciones, y al terminar estas siempre
                se nos devuelve al menú, hasta que presionamos salir. El bucle while siempre estará ejecutando un bloque de código por el que podremos movernos recursivamente.
                </p>
  
                <img class="foto" src={img26} width="70%"/>
  
                <p class="pulsar">
                Lo primero la traducción, que sería <b>"mientras que"</b>, por tanto le estaríamos diciendo al sistema "mientras que se cumpla esta condición, repite este código".<br />
                Pasamos a explicar el código. Hemos declarado dos variables, una de ellas booleana la cual inicializamos con un valor verdadero. Después creamos un objeto scanner de la
                clase <b>System</b> cuya misión es recoger los datos teclados. En la estructura while ponemos nuestra variable booleana la cual hará repetir el código de las llaves una y otra vez, ya que
                la hemos inicializado con true. El código dentro del while imita a un menú simple mediante un if anidado, jugando con el valor que tiene la variable <b>selección</b>. Cuando tecleamos un
                tres la estructura if cambiara el valor de <b>selección</b> y se saldremos del bucle while. De esta manera podemos hacer construcciones de código recursivo, poniendo todas las opciones y acciones
                que deseemos y pudiendo controlar la salida de este.
                </p>
  
                <h3>Bucle do-while</h3>
  
                <p class ="pulsar">
                Estamos hablando también de una estructura repetitiva controlada por sucesos, pero en este caso presenta una manera de funcionar inversa a <b>while</b>. Esta estructura primero ejecutara
                el código y si encuentra que la variable asignada es verdadera lo repetirá. La utilizaremos en los casos donde el bloque de código se deba ejecutar, al menos una vez, y la repetición dependa
                de otros factores.
                </p>
  
                <img class="foto" src={img27} width="60%"/>
  
                <p class="pulsar">
                Para simplificar hemos utilizado el mismo ejemplo del código anterior. Podéis ver que ahora primero se ejecutaría el código que metamos entre llaves y posteriormente se vería si se
                cumple la condición para repetir la ejecución del código.
                </p>
  
                <h3>Sentencia break y continue</h3>
  
                <p class="pulsar" id="ult">
                Ahora vamos a hablar de un par de sentencias interesantes para utilizar dentro de las estructuras mencionadas. La sentencia <b>break;</b> dentro de una estructura de control forzara su salida,
                pero solo de la estructura donde se halle, en el caso de estructuras anidadas (unas dentro de otras). Por ejemplo en el código de la estructura while podríamos haber puesto al seleccionar
                un tres directamente esta sentencia, la cual forzaría su salida y nos daría el mismo efecto.<br /><br />
                En cambio la sentencia <b>continue</b> dentro de las estructuras <b>while, for y do-while</b> dará por terminada la iteración actual y se ejecutara una nueva iteración, evaluando de nuevo
                nuestra condición.
                </p>
  
      </section>
  
            )
            break;
            case 10:
              return(
      <section class="contenido" id="punto">
  
                <h1>Entrada y salida de datos</h1>
  
                <p class="pulsar">
                    <a href="https://www.freepik.es/vector-gratis/direccion-flecha-luz-neon-perspectiva_6024598.htm#query=neon%20flechas&position=0&from_view=search&track=sph" target="_blank">
                      <img class="img" src={img01}/>
                    </a>
                La mayoría de las veces nuestros programas necesitaran acceder a recursos del sistema, como puede ser el caso de leer datos por teclado y escribir datos a través del monitor. Para estas
                situaciones Java utiliza la clase <b>System</b>:
                </p>
  
                <ul class="pulsar" id="lista">
                    <li><b>System.in.</b> Se trata de la entrada estándar de datos, el teclado.</li>
                    <li><b>System.out.</b> Se trata de la salida estándar de datos, el monitor o pantalla.</li>
                    <li><b>System.err.</b> Salida estándar de error por pantalla. Exclusiva para mostrar errores.</li>
                </ul>
  
                <p class="pulsar">
                Hablamos de una clase, por tanto, tendrá sus objetos y sus métodos. Pero la clase <b>System</b> no permite crear objetos, si no que los usaremos directamente llamando a sus métodos mediante
                el operador punto <b> (.).</b> <br />Una vez aclarado esto vamos a detenernos un momento en la entrada de datos. Como bien sabéis Java es un lenguaje que esta en constante evolución y a partir de la versión
                1.5 incorporo la clase <b>java.util.Scanner</b> con la que podremos leer multitud de tipos de datos. La clase <b>System</b> usada para leer datos nos limitaba a solo poder leer cadenas de texto,
                lo cual genera bastantes errores que se deben recoger y tratar, termina siendo bastante "dura", y teniendo la oportunidad de utilizar las clases que nos brinda el avance del lenguaje por qué
                no hacerlo. No obstante recomiendo que probéis las dos clases y trabajéis con la que os sintáis más cómodos.<br />
                </p>
  
                <h3>Entrada de datos. Clase Scanner</h3>
  
                <img class="foto" src={img28} width="60%"/>
  
                <p class="pulsar">
                Para poder leer datos con la clase <b>Scanner</b> sí debemos crear un objeto. Como veis hemos creado el objeto <b>entrada</b> de la clase <b>Scanner</b>, seguidamente tenemos una salida por pantalla
                solicitando datos, y recogemos esos datos guardándolos en la variable <b>selección</b> llamando a uno de los métodos que contiene la clase. Para llamar a estos métodos usamos el objeto
                creado y llamamos con el operador punto, quedando así <b>entrada.nextInt();</b><br />Esta clase es bastante amplia
                así que de momento vamos a dejar simplemente dos métodos que nos permitirán leer datos, y conforme toquemos conceptos que puedan ayudarse de la clase Scanner los pasaremos a explicar.
                </p>
  
                <ul class="pulsar" id="lista">
                    <li><b>nextLine();</b> Leerá toda la línea guardando el dato como una cadena de texto.</li>
                    <li><b>nextInt();</b> Leerá los datos pero en este caso los guarda como un numero entero.</li>
                </ul>
  
                <p class="pulsar">
                Hay un concepto que debéis tener en cuenta. Cuando se crea un programa nunca sabemos lo que va a teclear un usuario realmente, y tratar de meter un texto cuando se espera un dato numérico
                genera un error. Un error que debemos recoger y mostrar al usuario para que lo subsane. En este mismo tema veremos como recoger y tratar estos errores.
                </p>
  
                <h3>Salida de datos. Clase System</h3>
  
                <p class="pulsar">
                La salida por pantalla la realizamos con el objeto <b>System.out</b> que nos permite llamar una serie de métodos sin la necesidad de crearlo, os dejamos los métodos que más usareis para
                mostrar datos. Con esta clase pasa lo mismo que con <b>Scanner</b> es una clase muy amplia y de momento iremos explicando los que vayamos a necesitar.
                </p>
  
                <ul class="pulsar" id="lista">
                    <li><b>System.out.print</b> Con este método escribiremos una cadena de texto.</li>
                    <li><b>System.out.println</b> Escribiremos una cadena de texto y finalizara con un salto de línea, es más estético y ordenado.</li>
                    <li><b>System.out.printf</b> Escribe una cadena de texto con formato para la variable.</li>
                </ul>
  
                <img class="foto" src={img29} width="60%"/>
  
                <p class="pulsar">
                En la imagen de arriba veréis como hemos usado los tres métodos. Hemos imprimido un nombre con las dos primeras sentencias de salida de datos. Podemos observar en el output como ambas
                salidas aparecen juntas y sin separación, ya que <b>print</b> no termina con un salto de línea. Con estos dos métodos cuando queramos escribir un mensaje y el valor de la variable debemos
                utilizar el operador <b>+</b> de concatenación de cadenas. En cambio la segunda sentencia utilizando <b>println</b> termina con un salto de línea, y por eso la tercera salida ya aparece
                debajo de forma más ordenada.<br />
                Por otro lado observamos como usando <b>printf</b> podemos dar formato de salida a la variable. En este caso hemos indicado que nos imprima la variable con dos cifras significativas,
                este método nos permitirá filtrar la salida de la variable, os dejamos una tabla con la explicación de los comandos.
                </p>
  
                <img class="foto" src={img30} width="75%"/>
  
                <h3>Gestión de errores en entrada de datos</h3>
  
                <p class="pulsar">
                Como os comentábamos anteriormente, nunca sabemos lo que el usuario de nuestro programa tecleara ante nuestras preguntas. Por tanto es bastante probable que no se introduzca el dato
                solicitado, o bien en el formato solicitado, así que necesitaremos que nuestro programa sepa qué hacer ante estas situaciones. Para ello utilizaremos los bloques <b>try catch</b>.
                Como su traducción nos indica se trata de "intentar y capturar". Le vamos a indicar a Java que intente ejecutar un trozo de código susceptible de errores y si se dan estos errores le
                indicaremos que debe hacer al capturar este error.
                </p>
  
                <img class="foto" src={img31} width="65%"/>
  
                <p class="pulsar">
                El ejemplo es bastante simple, nos pide un dato numérico pero le introducimos una letra, un tipo de dato que Java no espera. Esto genera un error que <b>catch</b> captura. En este caso
                mediante <b>Exception e</b> le indicamos que capture cualquier tipo de error y ejecute el código que le indicamos. Podemos poner infinitos catch que recogerían errores concretos indicando
                a Java qué hacer con cada tipo de error que se encuentre.<br />
                En cambio si no usamos estos bloques <b> try catch</b> el programa al encontrarse el error detendría su ejecución.
                </p>
  
                <img class="foto" src={img32} width="65%"/>
  
                <p class="pulsar" id="ult">
                Vemos como el uso de estos bloques nos soluciona el problema de que el programa detenga su ejecución pero no nos ayuda a obtener el dato que necesitamos. Para ello necesitamos que
                la pregunta sea recursiva y se este repitiendo una y otra vez hasta que se obtenga el dato correcto. Para estos casos existen otras maneras que veremos un poco más adelante.
                </p>
  
      </section>
            )
            break;
            case 11:
              return(
      <section class="contenido" id="punto">
  
                <h1>Programación orientada a objetos</h1>
  
                <p class="pulsar">
                  <a href="https://www.freepik.es/vector-gratis/cubos-plastico-o-vidrio-que-brillan-luz-neon-diferentes-angulos-vista-caja-cuadrada-transparente-bloque-cristal-acuario-o-podio-exhibicion-objetos-geometricos-brillantes-aislados-ilustracion-vectorial-3d-realista_12089319.htm#query=neon%20objetos&position=30&from_view=search&track=sph" target="_blank">
                    <img class="img" src={img33} />
                  </a>
  
                La programación orientada a objetos es un paradigma de programación, una manera de programar. Este tipo de
                programación es una abstracción, una manera de acercar el código a un pensamiento más humano para así poder dividir el programa en trozos más pequeños y entendibles.
                Este tipo de programación es la que nos hará sumergirnos en los conceptos de clases y métodos para entender las tres características principales que tiene este paradigma.
                </p>
  
                <h3>Encapsulamiento</h3>
  
                <p class="pulsar" id="caja">
                El encapsulamiento es el mecanismo básico para ocultar la información de los atributos de un objeto a los demás objetos de la aplicación. Con esto podemos ocultar la información de nuestros
                objetos hacia el mundo exterior o bien restringir el acceso para evitar su manipulación. Por tanto los objetos lo único que conocerán de otros objetos será lo que los métodos que nosotros
                creemos le permitan ver o manipular, teniendo así un control del flujo de la información que nos ayudara a poder crear programas seguros.
                </p>
  
                <h3>Herencia</h3>
  
                <p class="pulsar" id="caja">
                También conocida como especialización o generalización, nos permitirá crear una clase nueva en términos de una clase ya existente, (herencia simple) o de varias clases ya existentes (herencia
                múltiple), heredando sus atributos y métodos. Podríamos crear una clase electrodoméstico con unos atributos genéricos como pueden ser el color, el peso, el consumo, el precio de venta y
                heredar de esta los distintos tipos de electrodomésticos como nevera, lavadora, microondas o cualquier electrodoméstico. Estos arrastraran los métodos y los atributos de su clase padre.
                </p>
  
                <h3>Polimorfismo</h3>
  
                <p class="pulsar" id="caja">
                El polimorfismo nos indica como distintas clases que heredan de una misma pueden realizar una misma acción de forma diferente. Cuando una clase hereda de otra tiene los atributos y métodos
                de su clase padre, pero estos métodos se pueden sobrescribir. Por ejemplo si pensamos en la case mamíferos muchos animales heredan de ella pero no todos tiene la misma forma de desplazarse.
                Unos a cuatro patas, otros a dos, e incluso algunos son marinos y lo harían nadando. Todos hacen lo mismo pero de distinta manera.
                </p>
  
                <h3>Abstracción</h3>
  
                <p class="pulsar">
                Lo que vamos es a construir nuestras propias clases con sus atributos y sus métodos pero todo esto lo vamos a realizar mediante la abstracción. Imaginemos necesitamos crear un programa
                que gestione los vehículos que tiene un taller mecánico de motocicletas, necesitamos traer objetos del mundo real a nuestro modelo computacional:
                </p>
  
                <ul class="pulsar" id="lista">
                    <li>Garaje</li>
                    <li>Motocicleta</li>
                </ul>
  
                <p class="pulsar">
                Muy bien ya tendríamos dos clases en las que trabajar. Ahora deberíamos pensar que acciones necesitamos que desarrollen nuestros objetos, esto ira en función de la necesidad del objetivo
                del programa.
                </p>
  
                <ul class="pulsar" id="lista">
                    <li>Garaje. Almacenar, identificar y clasificar las motos.</li>
                    <li>Motocicleta. Identificar la moto y las partes averiadas.</li>
                </ul>
  
                <p class="pulsar">
                Esta es la abstracción a la que nos referimos, cuando programamos usando POO debemos tener esta manera de pensar. Las clases que necesitamos crear, garaje y motocicleta, implementaran
                los métodos que nosotros creamos convenientes y serán llamados mediante los objetos en el flujo principal del desarrollo del programa. Esto nos permitirá trabajar por separado clases y
                métodos y el flujo principal del programa. Además por si no os habéis dado cuenta estas clases pueden funcionar integrándose en otros programas, lo que nos permite reutilizar el código creado.
                Imaginad ahora que vamos a crear un concesionario de coches, podríamos utilizar la clase garaje que ya tenemos creada.
                </p>
  
                <h3>Clase</h3>
  
                <p class="pulsar">
                Las clases van a ser el "molde" de los objetos, ya que aquí es donde vamos a definir como se va a comportar el objeto (métodos) y que datos lo van a identificar (atributos). No todas las clases
                van a definir exactamente un objeto real, pero para empezar a entenderlas las veremos así. Estas clases las crearemos aparte del flujo principal del programa, así cuando creemos un programa
                podemos comenzar por crear sus clases y después darle forma al código principal, es una manera de trabajar más organizada que nos permitirá ver con más precisión todos los objetivos
                del programa. Veamos una clase.
                </p>
  
                <img class="foto" src={img34} width="70%"/>
  
                <p class="pulsar">
                Arriba podéis ver una clase de principio a fin la cual hemos ilustrado con una serie de comentarios para que os facilite su comprensión. Existen distintos tipos de clase que podremos
                utilizar mediante un modificador. Estos modificadores definen ciertas características que tendrá la clase, en nuestro ejemplo hemos utilizado el modificador <b>public</b>, os dejamos una
                tabla con la definición de los modificadores.
                </p>
  
                <img class="foto" src={img35} width="70%"/>
  
                <h3>Atributos</h3>
  
                <p class="pulsar">
                Los atributos es lo que nos dirá cual es el estado del objeto en determinado momento. Los atributos que componen el objeto son los que le otorgan su identidad, por eso podremos tener muchos
                objetos de una misma clase siendo todos distintos entre si, de la misma manera que podemos tener muchos coches de distintas marcas y cilindradas.
                </p>
  
                <img class="foto" src={img36} width="70%"/>
  
                <p class="pulsar">
                Si echamos un vistazo a los atributos de la clase que estamos analizando nos habremos dado cuenta del comentario sobre la encapsulación de estos y podemos ver que están precedidos de la
                palabra <b>protected</b>. Como en el caso de las clases los atributos también tienen modificadores de acceso para proteger la información que contienen.
                </p>
  
                <img class="foto" src={img37} width="70%"/>
  
                <p class="pulsar">
                Cuando utilizamos la encapsulación, como norma general, necesitaremos crear métodos get y set. Estos métodos los utilizaremos para mostrar o modificar el valor de los atributos desde fuera de la clase. El métodos get
                (obtener) nos mostrara el valor del atributo, y el método set (colocar) nos valdrá para modificar dicho valor. Estos métodos siempre serán <b>public</b> ya que su función es realizar las
                acciones desde fuera de la clase con el objeto.
                </p>
  
                <p class="pulsar">
                La clase que estamos analizando es una superclase, de donde heredaran objetos como lavadora y diversos electrodomésticos. Por este motivo ponemos los atributos en <b>protected</b>
                para que se pueda acceder a ellos y modificarlos. Como en este caso sí que era de interés poder crear objetos de esta clase no se declaro como <b>abstract</b>.
                </p>
  
                <h3>Constructores</h3>
  
                <p class="pulsar">
                Para crear el objeto de la clase desde el flujo principal del programa necesitamos llamar al constructor y pasarle determinados parámetros que nosotros mismos configuramos en la clase.
                Todas las clases disponen de un constructor por defecto que podemos llamar sin pasarle parámetros.
                </p>
  
                <img class="foto" src={img38} width="60%"/>
  
                <p class="pulsar">
                Arriba podemos ver los distintos constructores de la clase. Cuando implementemos un constructor debemos tratar que sea lo más eficiente posible, llamando a métodos para realizar comprobaciones
                o utilizando valores por defecto o cualquier tipo de acción. Si hacemos esto ahorraremos código y tendremos automatizadas varias tareas.<br /> Como veis nuestros constructores no se limitan a construir
                el objeto si no que hacen uso de constantes y métodos para saber muy bien el estado inicial en el que creamos el objeto. El operador <b>this</b>, traducción "esto/e", sirve para hacer referencia a los
                atributos de un objeto cuando estamos dentro de él, por eso cuando creamos un constructor lo utilizamos para indicar que parámetro solicitado debe ir a que atributo. Es una mera de decirle
                a Java "esto pertenece a esto".<br />
                Pero en el primer constructor nos sirve para llamar al constructor por defecto y configurarlo. Así cuando llamemos al constructor sin pasarle parámetros esto creara el objeto con los
                valores constantes que le hemos indicado.
                </p>
  
                <img class="foto" src={img39} width="60%"/>
  
                <p class="pulsar">
                En la imagen podéis ver como se hacen las llamadas a los constructores para obtener el objeto. Hemos llamado por orden a los tres constructores creados en la clase Electrodoméstico.<br />
                Como Java es un lenguaje tipado primero le decimos que tipo de información va a contener la variable <b>Electrodoméstico electro1</b> y automáticamente creamos el objeto llamando al
                constructor. Para llamar al método utilizamos su nombre y entre paréntesis pondremos los datos secuencialmente teniendo cuidado de poner el tipo de dato correcto y en su posición, si no hacemos esto
                Netbeans nos indicara un error.
                </p>
  
                <h3>Métodos</h3>
  
                <p class="pulsar">
                Los métodos son muy parecidos a las funciones matemáticas. Tiene un cuerpo lógico donde se va a trabajar con los datos y unas variables o incógnitas que vamos a pasarle como parámetros.
                Estos parámetros se trabajaran y terminaran dando un resultado. Las funciones podrán devolvernos un valor, o simplemente validar algo sin necesidad de devolvernos nada. Os mostramos un método
                y pasamos a analizarlo.
                </p>
  
                <img class="foto" src={img40} width="60%"/>
  
                <p class="pulsar">
                Los métodos al igual que las clases y atributos también tiene modificadores de acceso. El método comienza con el comando <b>private</b> como modificador, dejamos una tabla de los
                modificadores que tenemos disponibles para lo métodos.
                </p>
  
                <img class="foto" src={img41} width="70%"/>
  
                <p class="pulsar">
                Como comentábamos antes los métodos también pueden devolver un valor o simplemente validar algo que nos interesa sin necesidad de que nos devuelva nada. Esto lo indicaremos con el segundo
                comando, donde nosotros hemos indicado <b>void</b> traducido "vacio". Esto es lo que indicaremos cuando no queremos que nos devuelva ningún valor, si por el contrario necesitamos que
                nos devuelva un valor debemos indicar el tipo de dato que nos debe devolver int, String, char...
                </p>
  
                <img class="foto" src={img42} width="70%"/>
  
                <p class="pulsar" id="ult">
                Podemos ver claramente en la imagen de arriba donde debemos situar el tipo de dato que queremos que nos devuelva el método, recordad usar <b>void</b> cuando queráis indicar que no devuelva
                ningún dato.<br /><br />
                A continuación encontraremos el cuerpo del método que esta encerrado entre llaves, aquí implementaremos el código necesario para que cumpla la función que necesitemos.
                <br /><br />
                <img class="foto" src={img43} width="60%"/>
                </p>
  
      </section>
              )
              break;
              case 12:
             
                return(
      <section class="contenido" id="punto">
      
        
  
                <h1>Estructuras de almacenamiento</h1>
  
  
                <p class="pulsar">
  
                  <a href="https://www.freepik.es/vector-gratis/procesamiento-flujo-datos-isometricos-proteccion-datos-proceso-encriptacion-sala-servidores_3629627.htm#query=neon%20almacen&position=35&from_view=search&track=sph" target="_blank">
                    <img class="primera_img" src={img44}/>
                  </a>
  
                Estamos hablando de listas o "arrays" en inglés. Cuando comencemos a crear programas más avanzados nos encontraremos con la necesidad de almacenar mucha información y manejarla según
                nuestra necesidad, para estos casos usaremos las estructuras de almacenamiento. Existen distintas estructuras que se agrupan según una serie de funciones que nos ofrecen, pero verlo
                desde este prisma resulta complejo de una manera completamente innecesaria. Vamos a tratar de agruparlas en función a lo que realmente es importante y vamos a necesitar cuando implementemos
                código, <b>recalcar que las listas comienzan a contar desde la posición 0</b>. Otra cosa muy importante que nos hará utilizar una lista de una forma u otra es si vamos a almacenar datos del mismo tipo o diferentes.
                </p>
  
                <h3>Finitas o estáticas</h3> <br /><br />
  
                <p class="pulsar">
                Dentro de este grupo encontraremos listas que tendrán un tamaño definido, el cual controlaremos nosotros, conocidas como su traducción al inglés "arrays". Los arrays pueden ser unidimensionales
                o multidimensionales y se trabajaran como una matriz. Pensad que estamos hablando de listas, que aunque se trabajen como una matriz, quizás no sea la mejor manera de verlas, puede resultar
                compleja. A las listas debemos indicarles el tipo de dato que van a almacenar. Vamos a ver las formas de declarar una lista en Java, dejamos unos ejemplos numerados y pasamos con su explicación.
                </p>
  
                <Highlight className='java'>
                      {ejemplo1}
                </Highlight>
                
                    <br /><br />
                <ol class="pulsar" id="lista">
                    <li>En este primer ejemplo lo que se hace es declarar el array, primero definimos que la variable va a ser un array, mediante los corchetes, y luego ya definimos la lista y guardamos el espacio en memoria.
                    Es una manera de declaración en dos partes, muchas veces lo veréis cuando leáis código, tiene unos usos definidos que veremos más adelante, pero lo más practico para empezar es definirla
                    completa en una sola sentencia.</li>
                    <br /><br />
                    <li>Aqui ya vemos la declaración completa del array en una sola sentencia. Esta declaración es lo que necesitamos para utilizar la lista, fijaos como al final indicamos el tamaño de que va a tener [10].
                    <b>Para empezar lo mejor es que realicéis la declaración de este modo y comencéis a utilizar la lista</b>. Ambas maneras de declarar, la 1 y la 2, nos llevan exactamente al mismo lugar.</li>
                    <br /><br />
                    <li>Para introducir datos en la lista lo haríamos de esta manera, indicamos la posición y le pasamos el dato. Ojo que las listas comienzan a contar desde 0, es decir la lista que hemos
                    declarado tiene capacidad para 10 números enteros, las posiciones serán el intervalo del [0] al [9], que equivale a las 10 posiciones. Vemos seguidamente un ciclo for, la manera más
                    común de pasarle valores a este tipo de listas. Mediante el ciclo for podemos recorrer las posiciones de esta e introducir valores, aquí ya tendréis que usar el ciclo en función de lo
                    que necesitéis.</li>
                    <br /><br />
                    <li> En esta última manera de declarar no es necesario que especifiquemos el tamaño de la lista, ya que le vamos a pasar los valores que almacenara, y ese será su tamaño. Vemos
                    en color rojo como se le pasan los valores a la lista. Recomendamos usarla en caso de que sean valores que ya se conocen, y que no sean muchos.</li>
                    <br /><br />
                </ol>
                <p class="pulsar">
                Como conclusión sacamos que para comenzar a programar lo mejor es declarar como en el ejemplo 2 y comenzar a usar la lista, el ejemplo 1 lo hemos puesto para que lo tengáis claro, lo
                vais a ver muchas veces cuando consultéis código. Recomendamos pasar valores a las listas utilizando un bucle for, pensad bien la manera correcta de usarlo a vuestro favor, no desesperéis y
                usad el ingenio.
                </p>
  
                <h3>Dinámicas</h3> <br /><br />
                <p class="pulsar">
                En este tipo de listas no necesitamos indicar su tamaño, estas se van ampliando o reduciendo en función de los elementos que introduzcamos. Existen diferentes tipos de listas dinámicas
                que tiene métodos de manejo distintos, nosotros nos centraremos en ver el tipo ArrayList, que posiblemente será el que más se adapte a las necesidades que os encontréis. Aquí hay poco que
                matizar:
                </p>
                <Highlight className='java'>
                    {ejemplo2}
                </Highlight>
                    <br /><br />
  
                <p class="pulsar">
                La lista es un elemento ArrayList, para introducir el dato que manejara se indica entre los símbolos de mayor menor. No necesitamos indicar el tamaño del array, la lista crecerá o decrecerá
                a demanda. Para introducir datos utilizamos el método <b>add</b> y para eliminarlo el método <b>remove</b>. Introducir datos es simple pero eliminarlos puede no serlo tanto, el método <b>
                remove</b> aceptara como parámetro el dato a eliminar o su posición en la lista. ArrayList contiene métodos que nos ayudaran a saber su tamaño, que posición tiene determinado dato y más
                que nos serán de utilidad. Tendréis que trabajar el ingenio para utilizar este tipo de listas a vuestro favor, lo más lógico será usarlas cuando no sabemos la cantidad de datos que vamos a trabajar.
  
                </p>
  
                <h3>¿Qué pasa si quiero guardar distintos tipos de datos?</h3> <br /><br />
  
                <p class="pulsar" id="ult">
                Como hemos comentado anteriormente nos podemos ver en la necesidad de querer almacenar distintos tipos de datos y queramos tener un orden, es decir no tener una lista para cada dato. Como
                hemos explicado a las listas debemos especificarle el dato que se va a almacenar, pero nuestras clases también son consideradas un tipo de dato. Por lo tanto es tan simple como crear un
                objeto que tanga por atributos los datos que necesitemos almacenar y crear una lista de ese tipo de objeto. Después podremos acceder a los datos mediante los métodos que hayamos programado
                y listo.
                <br />
                En la programación siempre existirán múltiples caminos para llegar al mismo destino, aquí os hemos expuesto una manera simple y fácil de almacenar varios tipos de datos pero existen muchas
                más. Lo importante es construir nuestros propios caminos adaptándolos lo más posible a nuestra situación.
                </p>
  
                  
      </section>
              )
              break;
              case 13:
                return(
      <section class="contenido" id="punto">
  
                <h1>Almacenamiento de datos y ficheros</h1>
  
                <p class="pulsar">
                <img class="primera_img" src={img45} width="33%"/>
                En esta sección vamos a ver como podemos trabajar y manipular ficheros desde java. En esta primera parte vamos a centrarnos en que nuestras aplicaciones tengan la capacidad de manipular
                información y en la siguiente sección veremos los conceptos de persistencia y serialización, que nos permitirá guardar el estado de nuestra aplicación. Anteriormente ya vimos la entrada y salida
                de datos a la hora de interactuar con usuarios, esta parte es muy similar pero interactuaremos con ficheros. Cabe destacar que podemos encontrarnos con diferentes maneras de acceso a un fichero,
                una manera secuencial y otra aleatoria, podéis investigar más sobre esta manera de acceder y crear ficheros desde java. Nosotros nos centraremos en el hecho de como gestionarlos sin pretender
                dar una explicación profunda. A partir de esta explicación en adelante trataremos de explicar las cosas de manera más practica saliendo un poco de la faceta teórica, vamos a proponer código
                tratando de que veáis las ventajas de la POO, <b>trataremos de usar siempre métodos y clases con la finalidad de obtener un código limpio y reutilizable.</b>
                </p>
  
                <h3>Flujos de datos</h3>
  
                <p class="pulsar">
                Llamamos flujos a los canales que abrimos para que entren o salgan datos, flujos de entrada o salida. Estamos hablando de lo mismo que en la sección de entrada y salida de datos, pero en
                este caso lo estamos haciendo con ficheros. Necesitaremos abrir flujos para poder trabajar con los ficheros, utilizaremos:
                </p>
  
                <ul class="pulsar" id="lista">
                            <li>FileWriter</li>
                            <li>FileReader</li>
                        </ul>
                <p class="pulsar">
                Estas clases están hechas para trabajar con caracteres, podemos encontrar otras indicada para trabajar con bytes. Con estas clases podremos acceder y leer o escribir en ficheros. También podemos
                ayudarnos de buffers para mejorar la comunicación.
                </p>
  
                <h3> Clase Java NIO Files</h3>
  
                <p class="pulsar">
                Vamos a trabajar con la API NIO (New IO), que es la API moderna IO. A partir de Java 7 se modernizo esta clase para tratar que fuera menos complicada de usar y nos facilitar las cosas a los
                programadores. Encontrareis por la red muchas referencias a la antigua API, ya que es una API bastante potente y se seguirá usando años, pero lo mejor es que nos centremos en lo más
                actual y poco a poco ya vayamos ampliando conocimientos, si no es demasiada información y os vais a ver abrumados. Con esta clase vamos a realizar las siguientes funciones básicas
                a la hora de trabajar con ficheros:
                </p>
                        <ul class="pulsar" id="lista">
                            <li>Comprobar la existencia de ficheros.</li>
                            <li>Crear ficheros.</li>
                            <li>Eliminar ficheros.</li>
                        </ul>
  
                <p class ="pulsar">
                Os presentamos un método que nos confirmará, mediante un booleano, si el fichero existe. Es bueno realizar estas comprobaciones para evitarnos errores no deseados así como poder avisar
                al usuario de la inexistencia del fichero. Como veis es un método simple que utiliza <b>Files.exists</b> para retornar un booleano que nos indica la existencia.
                </p>
  
                <Highlight className='java'>
                    {ejemplo3}
                </Highlight>
  
                <p class ="pulsar">
                Este otro método nos permite crear un fichero utilizando la función <b>createFile</b> en el directorio de trabajo. Nuestra aplicación, si no le indicamos ninguna ruta, trabaja en su directorio.
                Si quisiéramos que el archivo se creara en otro lugar deberíamos especificar la ruta teniendo en cuenta el SO que se esta utilizando.
                </p>
  
                <Highlight className='java'>
                    {ejemplo4}
                </Highlight>
  
                <p class ="pulsar">
                Este último método nos valdrá para eliminar ficheros, para ello nos valdremos de la función <b>deleteIfExists</b> que nos confirmara si ha borrado el archivo o, por el contrario, no se ha
                podido borrar porqué no existe.
                </p>
  
                <Highlight className='java'>
                    {ejemplo5}
                </Highlight>
  
                <h3>Escritura y lectura de ficheros</h3>
  
                <p class ="pulsar">
                Ahora vamos a ver como escribir y leer ficheros. Aún no estamos hablando de persistencia, si no de guardar datos o leer datos por el motivo que sea. Para estos casos hemos utilizado
                java IO, la api antigua, ya que nos permite crear un método con poco código y simple, de esta forma lo veréis más claro. Se debe tener en cuenta que estos métodos solo pretenden ilustrar
                de forma breve como se puede acceder a un fichero. Vosotros tendréis que crear vuestro métodos en función de lo que necesitéis utilizar, tendréis que echar mano de la documentación de las
                APIs para ver las posibilidades.
                </p>
  
                <Highlight className='java'>
                    {ejemplo6}
                </Highlight>
  
                <p class ="pulsar">
                En el siguiente método vemos como leer un archivo y hacer que el texto tenga una salida por pantalla.
                </p>
  
                <Highlight className='java'>
                {ejemplo7}
                </Highlight>
  
                <p class ="pulsar" id="ult">
                Hemos repasado las maneras más sencillas de acceso a ficheros para leer o escribir datos. Esto tiene poca utilidad, pero pretendemos nos sirva de introducción al almacenamiento de datos
                con intención de persistencia, es decir memorizar datos que almacenen el estado de nuestros objetos, mucho más útil. Esto ya nos permitirá crear aplicaciones donde persista la información,
                las aplicaciones no "olvidaran" su estado al cerrar su ejecución.
                </p>
  
      </section>
              )
              break;
              case 14:
                return (
      <section class="contenido" id="punto">
  
                <h1>Serialización y persistencia</h1>
  
                <p class="pulsar">
                La serialización es un proceso por el cual un objeto se convierte en una secuencia de bytes, que guardaremos en un fichero para, posteriormente, reconstruir el estado del objeto. De esta manera
                podremos crear aplicaciones que persistan mas allá del final de su ejecución. Todos los tipos primitivos son serializables en Java por defecto. Para que nuestros objetos puedan serializarse
                las clases que creemos deben implementar la interface serializable. Ejemplo de una clase serializable:
                </p>
  
                <Highlight className='java'>
                {ejemplo8}
                </Highlight>
                <br /><br />
                <p class="pulsar">
                Esta clase es serializable, puesto que implementa la interfaz serializable, <b>implements Serializable</b>. Simplemente con esta sentencia podremos serializar nuestros objetos.<br />
                Ahora lo que necesitaremos es saber como leer, para recuperar el estado de los objetos, y también poder escribir este estado.
                </p>
  
                <h3>Leer y escribir el estado de los objetos</h3>
  
                <p class="pulsar">
                Para la lectura y escritura de objetos utilizaremos:
                </p>
  
                <ul class="pulsar" id="lista">
                    <li>ObjectInputStream</li>
                    <li>ObjectOutputStream</li>
                </ul>
  
                <p class="pulsar">
                Estas clases necesitaran como parámetro un objeto de la clase FileInputStream o FileOutputStream respectivamente, que les indique el fichero con el que trabajar. <br />
                Guardar el estado del objeto:
                </p>
  
                <Highlight className='java'>
                {ejemplo9}
                </Highlight>
                <br /><br />
                <p class="pulsar">
                Usamos el método <b>writeObject</b> para escribir en el fichero el estado del objeto.<br />
                Para leer y recuperar el objeto:
                </p>
  
                <Highlight className='java'>
                {ejemplo10}
                </Highlight>
                <br /><br />
                <p class="pulsar">
                Usamos el método <b>readObject</b> para recuperar el estado del objeto. En la ultima sentencia lo que hacemos es hacer una instancia a nuestra clase y le indicamos que su estado
                será igual al objeto que vamos a recuperar. Nótese que hemos tenido que hacer un casting, <b>(PulsarLaTecla)</b>, ya que este método espera objetos de forma general. Tenemos que indicarle que el
                objeto que vamos a recuperar es del mismo tipo que el que hemos instanciado. <br />
                Muy importante indicar que los objetos se deben recuperar en el mismo orden en el que se guardan.
                </p>
  
                <h3>Conclusiones</h3>
                <p class="pulsar" id="ult">
                Hacer persistir las aplicaciones no resulta complejo, la complejidad irá creciendo conforme los datos a persistir aumenten. El siguiente paso en persistencia será utilizar bases de datos
                que conecten con nuestra aplicación, que nos permitirán tener un flujo de información constante y una persistencia más ordenada, en comparación con los ficheros, cuando hablamos de grandes
                volúmenes de datos. Además también permitirán que las aplicaciones puedan compartir información entre si. Para entrar en el mundo de las bases de datos vamos a necesitar conocer el lenguaje
                SQL así como las diferentes BD que existen y que posibilidades nos ofrecen.<br />
                En Java la conexión con una base de datos es bastante fácil, lo que necesitamos es tener conocimiento sobre bases de datos.
                </p>
  
  
        </section>
                )
                break;
                
              }
              
              

            }

export default Tema;