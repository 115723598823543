import "highlight.js/styles/night-owl.css";
import Highlight from "react-highlight";
import './Pulsar.css';
import img01 from './todos_recursos/xml.png'
import img02 from './todos_recursos/latex.png'
import img03 from './todos_recursos/layoutMovil.png'
import img04 from './todos_recursos/latexCode.png'





const TemaXml = (valor) => {
  
    
    switch(valor){
      case 1: 
      return(
        <section class="contenido">

            <h1>Lenguaje de marcas XML</h1>

            <p class="pulsar">

              <a href="https://www.flaticon.com/free-icons/xml">
                <img class="primera_img" src={img01}/>
              </a>

            El lenguaje XML, o también conocido como lenguaje de marcas, es un metalenguaje muy flexible y basado en etiquetas que deriva de SGML (ISO 8879). Con este lenguaje
            se pueden lograr muchas cosas y, por tanto, tiene unos usos muy variados. No entraremos en dar una explicación teórica y profunda sobre este tipo de lenguaje ya que debido a la
            cantidad de usos posibles resultaría complejo de abordar. Por ejemplo, el lenguaje HTML con el es que esta creada esta web es también un lenguaje de marcas, podríamos decir que
            es una "variable" de XML. En este caso HTML contiene una serie de etiquetas que ya están definidas, en XML seremos nosotros quienes definiremos nuestras propias etiquetas. Como Este
            lenguaje está estandarizado, esta manera de describir algo mediante estas etiquetas será perfectamente comprendido en muchos ámbitos, una practica que va de la mano de esta extensión
            de XML es usarlo para estructurar información. Veamos como mediante estas etiquetas podremos codificar información y su presentación, es importante quedarse con este concepto
            puesto que esta forma estructurar también nos va a valer para exhibir y entonces este lenguaje será muy útil en conceptos de diseño.
          </p>


            <Highlight>
              &lt;?xml version="1.0" encoding="iso-8859-1"?&gt;<br />
            &lt;!DOCTYPE libro&gt;<br />
            &lt;agenda&gt;<br />
            &nbsp;&lt;contactos&gt;<br />
            &nbsp;&nbsp;&lt;contacto&gt;<br />
            &nbsp;&nbsp;&nbsp; &lt;Nombre&gt;PulsarLaTecla&lt;/Nombre&gt;<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;mail&gt;contacto@pulsarlatecla.es&lt;/mail&gt;<br />
            &nbsp;&nbsp;&lt;/contacto&gt;<br />
            &nbsp;&lt;/contactos&gt;<br />
            &lt;/agenda&gt;<br />
            </Highlight>
            <br /><br />

           <p class="pulsar">

           Lo que observamos arriba es una estructura de la información por medio de etiquetas XML. Al ser un lenguaje muy extendido resulta fácil comunicarse de esta forma, es fácilmente interpretable
           por cualquier tipo de programa y nos permite comunicar las estructuras de la información sin entrar en más detalle. Aquí tendríamos una agenda que contiene contactos, con únicamente un
           contacto que esta formado por el nombre y el mail.
           <br /> <br />
           En este caso, estamos usando XML y podemos crear nuestras propias etiquetas, y adaptarlo así a cualquier situación que necesitemos. En cambio si usamos HTML, aunque la aplicación
           sea prácticamente igual, aquí ya existen una serie de etiquetas predefinidas que iremos adaptando a nuestras situaciones, como puede ser poner un párrafo, mostrar un titulo... En este
           caso estamos estructurando información, pero al mismo tiempo estamos estableciendo un diseño, ya que indicamos la estructura de la información y como debe mostrarse.
            <br /> <br />
            Aunque esta forma de describir el lenguaje sea paupérrima, es más, cualquier usuario avanzado o purista de XML nos dirá que esta descripción no termina de encajar con la potencia
            de este lenguaje. Pero nuestra intención es que comprendáis cual es la finalidad, más allá de sus múltiples usos y teoría, pues comprender esta finalidad descriptiva
            nos ayudara a usarlo en conceptos de diseño o estructura de información.
         </p><br /><br />

            


        </section>
      )
        break;
    case 2:
        return(
            <section class="contenido">

                  <h1>Etiquetas y atributos XML</h1>

                  <Highlight>
                    &lt;?xml version="1.0" encoding="iso-8859-1"?&gt;<br />
                  &lt;!DOCTYPE libro&gt;<br />
                  &lt;libro&gt;<br />
                  &nbsp;&lt;titulo&gt;pulsarLaTecla&lt;/titulo&gt;<br />
                  &nbsp;&lt;autor&gt;Dr.Code&lt;/autor&gt;<br />
                  &nbsp;&lt;autor&gt;Albert&lt;/autor&gt;<br />
                  &nbsp;&lt;editorial&gt;Ediciones Pulsar&lt;/editorial&gt;<br />
                  &nbsp;&lt;isbn&gt;552-56-245-12&lt;/isbn&gt;<br />
                  &nbsp;&lt;edicion&gt;1&lt;/edicion&gt;<br />
                  &nbsp;&lt;paginas&gt;150&lt;/paginas&gt;<br />
                  &lt;/libro&gt;<br />
                  </Highlight>
                  <br /><br />

                  <p class="pulsar">

                  Las etiquetas son conocidas también como elementos. Todo documento XML dispondrá siempre de un elemento raíz o root, en este ejemplo de arriba el elemento raíz sería
                  libro. El resto de etiquetas dispondrían la información y su representación dentro de este elemento raíz. Como la propia lógica nos indicara, el nombre que demos a estas
                  etiquetas será coherente con su contenido. De esta forma podremos codificar documentos que contendrán infinidad de información sin entrar en diseño. Todas las etiquetas
                  debe abrirse y cerrarse <b>&lt;paginas&gt;&lt;/paginas&gt;</b> conteniendo la información entre estas. Para este tipo de usos este lenguaje dispone de gran potencia,
                  pues nos permitirá codificar gran cantidad de información y además tenerla estructurada, al estar tan extendido resulta muy fácil compartir esta información en XML y
                  muchos programas serán capaces de interpretarla sin problema. También existen bases de datos puramente XML centradas en guardar la integridad total de estos documentos.
                </p>

                <h3>Atributos</h3>
                <p class="pulsar">

                Los atributos nos permitirán añadir propiedades, en forma de información adicional a un elemento. Siguiendo el mismo ejemplo inicial, imaginemos que tenemos distintos autores
                de un libro pero cada uno tiene un rol o función diferente.
              </p>

              <Highlight>
                  &lt;autor nombre= "Dr.Code" función= "Programador"&gt;&lt;/autor&gt;
                  &lt;autor nombre= "Albert" función= "editor"&gt;&lt;/autor&gt;
              </Highlight>
              <br /><br />

              <p class="pulsar">
                La limitación de los atributos es que estos no contiene ninguna jerarquía y no pueden ser divididos para interpretar la información y por tanto no contiene estructura
                lógica. Como podemos ver, el lenguaje XML nos vale para trabajar la información estructurada en documentos, que además deben estar bien formados con sus etiquetas de apertura
                y cierre además de su elemento raíz.<br />

                Pero en contra de lo que parece, este lenguaje y sus variables como HTML, van a ayudar a definir diseños para las vistas de nuestros programas o paginas web. Algo muy importante
                en la programación es comprender que los lenguajes son como herramientas, normalmente estarán definidos para un uso concreto, pero nosotros podemos encontrar usos muy diferentes
                para los que en un inicio fue concebido.
              </p>


                  


              </section>
        )
        break;
    
    case 3:
        return(
            <section class="contenido">

                <h1>Caso de uso, organización con Latex</h1>

                <p class="pulsar">
                Éste es uno de los usos que nos podemos encontrar con este lenguaje. El lenguaje de marcas nos ayudara a establecer las condiciones que necesitamos, por ejemplo dentro de un
                procesador de texto. Un procesador de texto, a diferencia de un editor como word, tiene un funcionamiento bastante diferente aunque nos entreguen resultados muy similares.
                El procesador no nos va a brindar conceptos visuales de edición, no veremos el titulo o los colores conforme avancemos en nuestra redacción, si no que en función de unas 
                sentencias generara el documento según lo indicado, muy similar a como se crean las paginas web en HTML. De esta forma solo tenemos que preocuparnos de nuestra redacción y 
                el procesador se encargara de crear el documento conforme a lo definido. Aunque aquí se mezclan conceptos de organización y diseño, resaltamos unicamente los de organización, ya
                que en un siguiente ejemplo veremos mucho más claro estos conceptos de diseño.<br />

                El procesador de texto Latex funciona de esta forma, ilustramos un pequeño ejemplo de como crearíamos un documento usando este software.
                </p>
              
              
                <img class="netbeans" src={img04}/>
              
              <br /><br />
            
              <p class="pulsar">
              Como se puede observar, primero tenemos unas definiciones sobre el papel, el tamaño, y una indicación sobre el tipo de documento que estamos redactando, en este caso
              como articulo. Esta indicación ya definirá bastante el diseño del documento final, ya que al indicarlo como articulo estamos estableciendo por detrás una serie de particularidades,
              como la alineación del texto, la separación entre lineas, el tipo de justificación... Seguidamente las primeras lineas nos traemos paquetes que nos ayudan a definir secciones necesarias
              en nuestra redacción y definimos también como se va a mostrar el contenido de las etiquetas, como con \lstset donde seleccionamos una serie de opciones para la etiqueta \lts, 
              que nos permite mostrar código de programación en lineas numeradas.<br />
              A partir de \begin document es donde comienza el contenido del articulo, se define un titulo y se establecen secciones. Aunque no hemos establecido como debe aparecer el titulo, este se mostrara 
              al inicio del documento y de forma ordenada.
            </p>

            <img class="netbeans" src={img02}/>
            <br /><br />

            <p class="pulsar">
              De esta forma, estableciendo con lenguaje de marcas estas definiciones, podemos dar orden a un documento generado con un procesador. Esto es mucho más eficiente que trabajarlo
              desde un editor, ya que una sola orden actúa sobre todos lo elementos del documento. También este comportamiento nos permite definir plantillas para que nuestros documentos
              sean siempre iguales y podamos olvidarnos de tener que estar definiéndolos una y otra vez. Es habitual trabajar de este modo cuando se entregan documentos científicos o profesionales.
            </p>

        </section>
        )
        break;
        case 4:
        return(
            <section class="contenido">

                  <h1>Diseño de una interfaz de usuario</h1>

                  <p class="pulsar">
                  Otra forma de usar este lenguaje es aplicable a diseños. En Android, se programa habitualmente mediante el modelo vista controlador. Esta forma de programar se basa en que por cada diseño
                  de la interfaz de usuario, existirá una clase con toda la lógica aplicable al diseño. Estos diseños de establecen usando lenguaje XML, veamos un pequeño ejemplo.
                  </p>
                
                
                <Highlight>
                &lt;xml version="1.0" encoding="utf-8"?&gt;<br/>
                &lt;androidx.constraintlayout.widget.ConstraintLayout xmlns:android="http://schemas.android.com/apk/res/android"<br/>
                xmlns:app="http://schemas.android.com/apk/res-auto"<br/>
                xmlns:tools="http://schemas.android.com/tools"<br/>
                android:layout_width="match_parent"<br/>
                android:layout_height="match_parent"<br/>
                tools:context=".controllers.MainActivity"<br/>
                android:background="#a9cce3"&gt;<br/><br/>

                
                &lt;ImageView<br/>
                android:id="@+id/imgSeguridad"<br/>
                android:layout_width="250dp"<br/>
                android:layout_height="250dp"<br/>
                android:src="@drawable/seguridad"<br/>
                android:scaleType="fitXY"<br/>
                app:layout_constraintTop_toTopOf="parent"<br/>
                app:layout_constraintStart_toStartOf="parent"<br/>
                app:layout_constraintEnd_toEndOf="parent"<br/>
                android:layout_marginTop="100dp"<br/>
                /&gt;<br/><br/>

                &lt;TextView<br/>
                android:id="@+id/txtIdentificarse"<br/>
                android:layout_width="wrap_content"<br/>
                android:layout_height="wrap_content"<br/>
                android:text="Para acceder debe identificarse"<br/>
                android:textColor="#00838f"<br/>
                app:layout_constraintBottom_toBottomOf="parent"<br/>
                app:layout_constraintLeft_toLeftOf="parent"<br/>
                app:layout_constraintRight_toRightOf="parent"<br/>
                app:layout_constraintTop_toTopOf="parent" /&gt;<br/><br/>

                &lt;EditText<br/>
                android:id="@+id/edUsuario"<br/>
                android:layout_width="250dp"<br/>
                android:layout_height="wrap_content"<br/>
                app:layout_constraintTop_toBottomOf="@id/txtIdentificarse"<br/>
                app:layout_constraintStart_toStartOf="parent"<br/>
                app:layout_constraintEnd_toEndOf="parent"<br/>
                android:hint="Usuario"/&gt;<br/><br/>

                &lt;EditText<br/>
                android:id="@+id/edContra"<br/>
                android:layout_width="250dp"<br/>
                android:layout_height="wrap_content"<br/>
                app:layout_constraintTop_toBottomOf="@id/edUsuario"<br/>
                app:layout_constraintStart_toStartOf="parent"<br/>
                app:layout_constraintEnd_toEndOf="parent"<br/>
                android:hint="Contraseña"/&gt;<br/><br/>

                &lt;Button<br/>
                android:id="@+id/btnentrar"<br/>
                android:layout_marginTop="20dp"<br/>
                android:layout_width="150dp"<br/>
                android:layout_height="wrap_content"<br/>
                app:layout_constraintTop_toBottomOf="@id/edContra"<br/>
                app:layout_constraintStart_toStartOf="parent"<br/>
                app:layout_constraintEnd_toEndOf="parent"<br/>
                android:background="@drawable/btonlog"<br/>
                android:text="Entrar"<br/>
                android:onClick="acceso"/&gt;<br/><br/>

                &lt;/androidx.constraintlayout.widget.ConstraintLayout&gt;
                </Highlight>
                
                
                <br /><br />
              
                <p class="pulsar">
                En este caso, cada etiqueta constituye un elemento del layout o diseño, y dentro de la etiqueta establecemos las propiedades de estilo y posición. En este caso de Andorid las
                etiquetas ya están definidas e identifican un tipo de componente, como puede ser un botón, una caja de texto, una imagen... Independientemente del uso que le demos, siempre 
                vamos a tener la existencia de una etiqueta padre, donde para este caso se establecen propiedades genéricas, como el color de fondo.<br />

                Como hemos aprendido, este lenguaje tiene diferentes usos que van desde estructura y orden para almacenamiento de datos, como vimos con la agenda de teléfonos, hasta los diseños de los 
                lauouts para aplicaciones móviles. El código mostrado nos daría esta vista en una aplicación móvil.
              </p>


              <img class="netbeans" src={img03}/>
              <br /><br />

              <p class="pulsar">
                Finalmente es fácil ver como difiere este lenguaje de otros que se usan para programar mediante sentencias, ya que no nos permite introducir lógica, pero aún así nos ofrece una 
                forma muy entendible de definir orden y diseño y se transforma en un lenguaje potente cuando lo combinamos con otros que sí nos aportan lógica, como en los diseños de Android.
              </p>

       </section>
        )
        break;
        }

}
export default TemaXml