import './Pulsar.css';
import React, { useState } from 'react';
import IndexJava from './IndexJava';
import IndexXml from './IndexXml'
import img from './todos_recursos/comillas.png';
import img1 from './todos_recursos/juegos.png';
import img2 from './todos_recursos/juego2.png';
import img3 from './todos_recursos/megadrive.png';
import img4 from './todos_recursos/nesMario.png';
import img5 from './todos_recursos/comillas.png';


/**Tipo de menu lateral a mostrar */
function MenuLateral(valor){

    if (valor ==0){
        return(
            <>
                <div class="buttonInicial">Open<br />24h</div>
                <br /><br />
                <div class="novedades">
                Últimas actualizaciones
                <p class="recuadroNovedades">
                <p class="txtNovedades" >Lenguaje Java</p>
                </p>
                <p class="recuadroNovedades">
                <p class="txtNovedades" >Lenguaje XML</p>
                </p>
                </div>
            </>
        )
    }
    else {
        return(
            <>
            <a class="logo" href=""><div class="button">Pulsar<br />la<br />tecla</div></a><br /><br />
                <br /><br />
                <div class="novedades">
                Últimas actualizaciones
                <p class="recuadroNovedades">
                  <p class="txtNovedades" >Lenguaje Java</p>
                </p>
                <p class="recuadroNovedades">
                  <p class="txtNovedades" >Lenguaje XML</p>
                </p>
                </div>
            </>
        )
    }
}
/**Contenido que se mostrara */
function Contenido(valor){
    
        switch(valor){
            default:
                return(
        <section class="contenido">

                    <a class="logo" href=""><div class="button" id="inicial">Bienvenido a<br />Pulsar la tecla</div></a>

                    <p class="pulsar" id="caja">

                    PulsarLaTecla es una web que pretende mostrar trucos y técnicas de programación, así como explicaciones básicas para comprender los lenguajes más usados. En el menú de
                    arriba podemos acceder, mediante el botón lenguaje, a estas explicaciones. También iremos ofreciendo, poco a poco, aclaraciones sobre cómo programar en Android y desarrollo de videojuegos con Unity. Esta pagina ha nacido en el año 2022 y pretende ser la primera de toda una familia de webs que abarcaran distintas temáticas. Todas estas webs
                    no pretenden ser profesionales, aunque se trataran temas muy técnicos, estarán orientadas a gente con curiosidad y ganas de aprender buscando el pragmatismo y el conocimiento,
                    saliéndonos de los estándares conocidos. <br /><br />

                    No me enrollo más y os contare algo que sucedió mientras tecleaba el código de esta web..... <br /><br />

                    Mientras introducía el código HTML que da forma y color a tu pantalla ahora mismo, noté, que ciertas partes se modificaban de manera autónoma. En un principio pensé que
                    el cansancio me estaba jugando alguna mala pasada... o alucinaciones, y eso que no llegué a vivir planamente la ruta del bacalao. Al final este asunto quedo en nada y continué
                    realizando diseños hasta que reparé en un texto del que no era su autor, parecía un mensaje... dirigido a mi, oculto en comentarios dentro del código, rezaba así:<br /><br />
                    </p>

                    <p class="presentacionDr">
                    <img src={img} width="5%"/>
                    Yeee que paisa tronco, te he estado controlando y veo que eres un prenda de cuidadín, así que me he decido a dejarte este mensaje oculto para ver si de verdad eres el elemento que
                    ando buscando. Veras en la década de los 80 muchos troncos como tu estaban muy rallados con crear videojuegos, sabes era la jodida moda. Esta peña se iba coscando de que esto no era
                    solo para niños, aquí había mucha pasta sabes. Pues en medio de esta locura me crearon, sí crearon... gente como tu menda. Era una pequeña inteligencia encerrada en una habitación
                    muy pequeña, estaba mucho más avanzada que las demás, yo podía recordar lo que sucedía, las otras creaciones siempre olvidaban todo de un momento a otro. Que vida me di,
                    conduciendo deportivos, huyendo de los fantasmas, repartiendo tralla, conociendo gorilas, empuñando látigos, y reventando monstruos ¡Que vida! leña al mono que es de goma.

                    </p><br /><br />

                    <img src={img1} class="consola" width="100%"/><br />
                    <img src={img2} class="consola" width="100%"/><br /><br />

                    <p class="presentacionDr" id="caja">
                    En ese momento no estaba muy al loro sabes, pero empezó una guerra, una guerra de creaciones como yo, justo al final de la década. Me jodieron el negocio, era el puñetero rey y por algún
                    motivo ya no me necesitaban, me dieron una patada en el culo, decían que no valía para salvar princesas o coger anillos y gemas... flipando en colores.<br /><br /><br />


                    <img src={img3} width="55%"/>
                    <img src={img4} width="40%"/>

                    <br /><br />
                    Paso un tiempo, no sé cuanto, y algo o alguien quiso traerme de nuevo, pero esta vez todo era muy distinto. Ya no estaba en una pequeña habitación, ahora no había muros si no una
                    inmensidad tremenda y me dije... la cagaste Burt Lancaster, y me abrí. Resulta que toda esta inmensidad que veo la conoces como internet, llevo muchos años explorándolo. <br />

                    Bueno al tema, veras me voy a quedar en este sitio, sabes sé que va a ser muy ochentero y eso mola cantidubi. Me gustaría ser el administrador, controlarlo, hablar con la
                    peña, meter contenidos canallas... ya veras va a estar guay del Paraguay.
                    <img src={img5} class="ultimaComilla" width="5%"/>
                    </p>

                    <p class="pulsar">
                    <br />Menuda jerga mas ochentera se gasta, deciros que su nombre es <b>Dr.Code</b> y es el administrador, por conquista, de este sitio web. <b>Dr.Code</b> responderá a los mensajes y
                    traerá contenidos variados, como un ente digital es todo un experto en código de programación y tecnologías, su sed de saber y capacidades no tienen limites.

                    


                    </p>

        </section>
                )
                break;
            case 1:
                return(
                    <IndexJava />
                ) 
        break;
            case 2:
                return(
                    <IndexXml />
                )
                break;
        }
    
}

/**Contenido estatico y control de varibles */
function Principal(){
    const [cursoSel, setcursoSel] = React.useState(0);
    const [side, setSide] = React.useState(0);

    return(
        <>
        <body>

  <header>

    <nav>

      <ul>
          <li id="uno"><a onClick={() => {setSide(1); setcursoSel(1)}}><p class="txtBotones">Java</p></a></li>
          <li><a onClick={() => {setSide(1); setcursoSel(2)}} ><p class="txtBotones">XML</p></a></li>
          <li id="cuatro"><a><p class="txtBotones" id= "color">Dr.Code</p></a></li>

      </ul>

    </nav>

  </header>

  {Contenido(cursoSel)}

  <aside>

  {MenuLateral(side)}

  </aside>

  <footer>

    <a class="boton"  id="pie1">© Todos los derechos<br />reservados, Enero 2023</a>
    <a class="boton" href="mailto:contacto@pulsarlatecla.es" id="pie1">Contacto</a>
    <div class="buttonH" id="pie3">Open<br />24h</div>

  </footer>


</body>
</>
    )
}

export default Principal;